import React, { useState } from 'react';
import i18n from 'utils/i18n';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useRouter } from 'next/router';

import { removeSpecialCharactersAndNumbers, tdvCellphoneMask, isValidTDVCellphone } from '/utils/forms';
import FormInput from 'components/atoms/form-input';
import Loader from 'components/atoms/loader';
import Button from 'components/atoms/button';
import Modal from 'components/molecules/simple-modal';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule RecallSearch
 */
function RecallSearch(props) {
  const t = i18n.useTranslations('components.recall-search');
  const { className = '', title, children, ...other } = props;

  const router = useRouter();
  const [isOpenVIN, setIsOpenVIN] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recallData, setRecallData] = useState({
    searched: false,
    data: null,
    vin: null
  });

  let validationSchema = yup
    .object()
    .shape({
      name: yup
        .string()
        .trim()
        .required(t('message_error_required_name'))
        .min(1, t('message_error_min_char_name'))
        .max(40, t('message_error_max_char_name')),
      lastName: yup
        .string()
        .trim()
        .required(t('message_error_required_last_name'))
        .min(1, t('message_error_min_char_last_name'))
        .max(80, t('message_error_max_char_last_name')),
      email: yup
        .string()
        .trim()
        .required(t('message_error_required_email'))
        .email(t('message_error_invalid_email'))
        .max(200, t('message_error_max_char_email')),
      customerId: yup
        .string()
        .trim()
        .required(t('message_error_required_customer_id'))
        .min(5, t('message_error_min_char_customer_id'))
        .max(17, t('message_error_max_char_customer_id')),
      vehicleIdentificationNumber: yup
        .string()
        .trim()
        .required(t('message_error_required_vehicle_identification_number'))
        .min(10, t('message_error_min_char_vehicle_identification_number'))
        .max(20, t('message_error_max_char_vehicle_identification_number')),
      cellphone: yup
        .string()
        .trim()
        .required(t('message_error_required_cellphone'))
        .test('test-invalid-cellphone', t('message_error_invalid_cellphone'), (cellphone) =>
          isValidTDVCellphone(cellphone)
        )
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      lastName: '',
      customerId: '',
      cellphone: '',
      email: '',
      vehicleIdentificationNumber: ''
    }
  });

  function onError(error) {
    console.log('Error: ', error);
  }

  function redirectToContactForm(chassiParam, campaignParam) {
    const query = {};

    if (chassiParam) {
      query.chassi = encodeURIComponent(chassiParam);
    }

    if (campaignParam) {
      query.campaign = encodeURIComponent(campaignParam);
    }
    router.push(
      {
        pathname: t('recall_contact_url'),
        query: query
      },
      t('recall_contact_url')
    );
  }

  async function onSubmit(params) {
    setIsLoading(true);

    try {
      const response = await fetch(
        `/api/recall/getRecallTDV?documentType=V&document=${params?.customerId}&name=${params?.name}&lastName=${params?.lastName}&phone=${params?.cellphone}&email=${params?.email}&serialVin=${params?.vehicleIdentificationNumber}`
      );

      if (!response?.ok) {
        throw new Error(`Request error: ${response?.statusText}`);
      }

      const { data } = await response.json();

      if (!data || !data?.recallFromTDVApi) {
        throw new Error('Invalid response format: Missing recallFromTDVApi');
      }

      const { recallFromTDVApi } = data || null;
      setRecallData({
        searched: true,
        data: recallFromTDVApi,
        vin: params.vehicleIdentificationNumber
      });

      reset();
    } catch (error) {
      console.error('Error fetching vehicle data:', error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <div className={`${css['molecule__recall-search-container']} ${className}`} {...other}>
        <div className={css['search-container']}>
          <h1 className={css['title']}>{title ? title : t('title')}</h1>

          <form onSubmit={handleSubmit(onSubmit, onError)} className={css['form-container']}>
            <div className={css['fields-container']}>
              <FormInput
                id="name"
                type="text"
                displayLabel={true}
                dataErrors={errors['name']}
                dataRegister={register('name')}
                label={t('label_name')}
                placeholder={t('placeholder_name')}
                masking={(event) => {
                  const value = event.target.value;
                  event.target.value = removeSpecialCharactersAndNumbers(value);
                }}
              />

              <FormInput
                id="lastName"
                type="text"
                displayLabel={true}
                dataErrors={errors['lastName']}
                dataRegister={register('lastName')}
                label={t('label_last_name')}
                placeholder={t('placeholder_last_name')}
                masking={(event) => {
                  const value = event.target.value;
                  event.target.value = removeSpecialCharactersAndNumbers(value);
                }}
              />

              <FormInput
                id="customerId"
                type="text"
                displayLabel={true}
                dataErrors={errors['customerId']}
                dataRegister={register('customerId')}
                label={t('label_customer_id')}
                placeholder={t('placeholder_customer_id')}
              />

              <FormInput
                id="cellphone"
                type="text"
                displayLabel={true}
                dataErrors={errors['cellphone']}
                dataRegister={register('cellphone')}
                label={t('label_cellphone')}
                placeholder={t('placeholder_cellphone')}
                masking={(event) => {
                  const value = event.target.value;
                  event.target.value = tdvCellphoneMask(value);
                }}
              />

              <FormInput
                id="email"
                type="text"
                displayLabel={true}
                dataErrors={errors['email']}
                dataRegister={register('email')}
                label={t('label_email')}
                placeholder={t('placeholder_email')}
              />

              <div>
                <FormInput
                  id="vehicleIdentificationNumber"
                  type="text"
                  displayLabel={true}
                  dataErrors={errors['vehicleIdentificationNumber']}
                  dataRegister={register('vehicleIdentificationNumber')}
                  label={t('label_vehicle_identification_number')}
                  placeholder={t('placeholder_vehicle_identification_number')}
                />

                <button className={css['modal-activate-button']} type="button" onClick={() => setIsOpenVIN(true)}>
                  {t('find_vehicle_identification_number')}
                </button>
              </div>
            </div>

            <button className={css['search-button']} disabled={!isValid || isLoading}>
              {t('search_button')}
            </button>
          </form>
        </div>

        {isLoading ? (
          <div className={css['loader-container']}>
            <Loader />
          </div>
        ) : (
          recallData.searched && (
            <>
              {recallData?.data?.exists && !recallData?.data?.attended && (
                <div id="hasrecall" className={css['recall-cards-container']}>
                  <h1 className={css['title']}>{t('title_has_recall')}</h1>

                  <ul className={css['card-list']}>
                    {recallData?.data?.recallCampaigns?.map((recall, index) => (
                      <li key={index} className={css['card']}>
                        <div className={css['card-info']}>
                          <span className={`${css['card-status-container-attention']} ${css['card-status-container']}`}>
                            <img src="/images/recall/icn-attention.svg" alt="attention icon" />
                            {t('attenction_title_has_recall')}
                          </span>

                          <div className={css['card-text']}>
                            <h2>{t('recall_title_has_recall')}</h2>
                            <p>{recall.name}</p>
                          </div>
                        </div>

                        <Button
                          color="ghost-2"
                          className={css['card-link']}
                          onClick={() => {
                            redirectToContactForm(recallData?.vin, recall?.name);
                          }}
                        >
                          {t('request_contact_has_recall')}
                          <img src="/images/recall/icn-arrow-right.svg" alt="arrow right" />
                        </Button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {recallData?.data?.exists && recallData?.data?.attended && (
                <div id="hasrepairedrecall" className={css['recall-cards-container']}>
                  <h1 className={css['title']}>{t('title_repaired_recall')}</h1>

                  <div className={css['card']}>
                    <div className={css['card-info']}>
                      <span className={`${css['card-status-container-success']} ${css['card-status-container']}`}>
                        <img src="/images/recall/icn-check.svg" alt="check icon" />
                        {t('repaired_title_repaired_recall')}
                      </span>

                      <div className={css['card-text-container']}>
                        <div className={css['card-text-second-variation']}>
                          <h2>{t('dealer_title_repaired_recall')}</h2>
                          <p>{recallData?.data?.dealer}</p>
                        </div>

                        <div className={css['card-text-second-variation']}>
                          <h2>{t('date_title_repaired_recall')}</h2>
                          <p>{recallData?.data?.approvationDate}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!recallData?.data?.exists && !recallData?.data?.attended && (
                <div id="hasnorecall" className={css['card']}>
                  <span className={`${css['card-status-container']} ${css['card-status-container-icon']}`}>
                    <img src="/images/recall/icn-check.svg" alt="check icon" />
                  </span>

                  <div className={css['card-text-third-variation']}>
                    <h2 className={css['title']}>{t('title_has_no_recall')}</h2>
                    <p>{t('text_has_no_recall')}</p>
                  </div>
                </div>
              )}
            </>
          )
        )}

        {isOpenVIN && (
          <Modal className={css['modal-container']} isOpen={isOpenVIN} onClose={() => setIsOpenVIN(false)}>
            <img src="/images/recall/vin.png" alt="VIN" />
          </Modal>
        )}
      </div>
    </>
  );
}

export default RecallSearch;
