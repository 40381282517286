/**
 * Query to find Vehicles binded by tenant locale
 * Expect 'locale' as variable
 *
 * @returns Promise<string>
 */
const getAllVehicles = () => {
  return `
    query getVehicles($locale: String!) {
      vehicles(locale: $locale, status: "ENABLED") {
        id
        name
        vehicleExternalId
        status
        category {
          id
          name
        }
        versions {
          id
          name
          hidePrice
          status
          modelYear {
            clientVehicleId
            medias {
              media {
                url
                title
              }
            }
            vehicleColors{
              color{
                name
                code
              }
            }
          }
        }
        mainVersion {
          id
          armoured
          name
          code
          hidePrice

          modelYear {
            clientVehicleId
            id
            year
            katashiki
            suffixCode
            defaultInternalColorCode
            vehicleColors{
              price
              color{
                name
                code
              }
            }
            medias {
              tag

              media {
                url
                title

                mediaType {
                  title
                }
              }
            }
          }
        }
      }
    }`;
};

const getAllVehiclesWithPage = () => {
  return `
  query getVehicles($locale: String!) {
    vehicles(locale: $locale, status: "ENABLED") {
      id
      name
      status
      page {
        id
        title
        fullpath
      }
    }
  }
  `;
};

/**
 * Query to find Vehicles with versions binded by tenant locale and removeFromBuildCar flag
 * Expect 'locale' as variable
 * Expect 'removeFromBuildCar' as variable
 *
 * @returns Promise<string>
 */
const getAllVehiclesWithVersions = () => {
  return `
  query getVehicles($locale: String!, $removeFromBuildCar: Boolean) {
    vehicles(locale: $locale, status: "ENABLED", removeFromBuildCar: $removeFromBuildCar) {
    id
    name
    status
    category {
        id
        name
      }

      page {
        id
        title
        fullpath
      }

      mainVersion{
        id
      }

      versions {
        id
        name
        code
        hidePrice
        status

        upgrade {
          name
          code

          modelYear {
            year

            version {
              code
              hidePrice
            }

            colorWithPrice:vehicleColors {
              price
              color {
                id
                base
                code
                rgb
                type
                name
              }
            }

            medias {
              tag
              media {
                id
                title
                description
                url
              }
            }

          }

        }

        accessories {
          name
          status
          price {
            value
            installment {
              value
              quantity
            }
          }
          partNumber
          type

          category {
            name
          }

          media {
            id
            title
            description
            url
          }

        }

        vehicle {
          name
          id
        }

        modelYear {
          year
          fuel
          katashiki
          testDrive
          disabled360
          suffixCode
          defaultInternalColorCode

          specs {
            id
            name

            contents {
              title
              abstract
              description

                media {
                  url
                  title

                  mediaType {
                    title
                  }

                }

            }

          }

          version {
            code
            hidePrice
          }

          color {
            id
            base
            code
            rgb
            type
            name
          }

          colorWithPrice:vehicleColors {
            price
            color {
              id
              base
              code
              rgb
              type
              name
            }
          }

          medias {
            tag

            media {
              url
              title

              mediaType {
                title
              }
            }
          }
        }
      }
    }
    }

  `;
};

/**
 * Query to find Vehicle by it id
 * Expect 'id' as variable
 *
 * @returns Promise<string>
 */
const getVehicle = async () => {
  return `
    query getVehicle($id: String!) {
      vehicle(id: $id) {
        name
        id
        status

        page {
          id
          title
          slug
          fullpath
        }

        mainVersion {
          id
          name
        }

        versions {
          id
          name
          code
          hidePrice
          status

          modelYear {
            id
            year
            fuel
            katashiki
            testDrive
            disabled360
            suffixCode
            defaultInternalColorCode

            specs {
              id
              name

              contents {

                title
                abstract
                description

                media {
                  url
                  title

                  mediaType {
                    title
                  }
                }
              }
            }

            color {
              id
              name
              rgb
              code
            }

            medias {
              tag

              media {
                url
                title

                mediaType {
                  title
                }
              }
            }
          }
        }

        accessories {
          id
          name

          media {
            url
            title

            mediaType {
              title
            }
          }

          price {
            value
            installment {
              value
              quantity
            }
          }

          page {
            id
            fullpath
          }
        }
      }
    }`;
};

/**
 * Query to find the set of Vehicles grouped by its Category
 * Expect 'locale' as variable
 *
 * @returns Promise<string>
 */
const getVehiclesByCategory = () => {
  return `
    query vehicleCategoriesByLocale($locale: String!) {
      vehicleCategories(locale: $locale) {
        id
        name

        models(status: "ENABLED") {
          name
        }
      }
    }`;
};

/**
 * Query to find the set of Vehicles grouped by its Category only for display, without prices, colors and versions
 * Expect 'locale' as variable
 *
 * @returns Promise<string>
 */
const getVehiclesByCategoryForDisplay = async () => {
  return `
  query vehicleCategoriesByLocale($locale: String!) {
    vehicleCategories(locale: $locale) {
      id
      name

      models(status: "ENABLED") {
        name
        vehicleExternalUrl
        removeFromBuildCar

        page {
          fullpath
        }

        mainVersion {
          name

          modelYear {
            available
            vehicleColors{
              price
            }

            medias {
              tag

              media {
                title
                url

                mediaType {
                  title
                }
              }
            }
          }
        }
      }
    }
  }`;
};

/**
 * Query to find Vehicle by Category
 * Expect 'categoryId' as variable
 *
 * @returns Promise<string>
 */
const getVehiclesByCategoryId = async () => {
  return `
  query vehiclesByCategory($categoryId: String!, $locale: String!) {
    vehicles(
      categoryId: $categoryId,
      locale: $locale,
      status: "ENABLED"
    ) {
      id
      name
      vehicleExternalUrl

      pdf_url:media {
        url
      }

      page {
        id
        title
        slug
        fullpath
      }

      mainVersion {
        name
        code
        hidePrice

        modelYear {
          year
        }
      }
    }
  }`;
};

/**
 * Query to find Vehicle by Page
 * Expect 'pageId' as variable
 *
 * @returns Promise<string>
 */
const findByPage = () => {
  return `
  query vehicle($pageId: String!) {
    vehicleByPage (pageId: $pageId) {
      name
      id
      vehicleExternalUrl

      pdf_url:media {
        url
      }

      page {
        fullpath
      }

      mainVersion {
        id
        modelYear {
          year
        }
      }

      media {
        url
        title
        mediaType {
          title
        }
      }

      versions {
        id
        name
        code
        hidePrice
        status
        kintoOnePersonal
        kintoOnePersonalLink
        motorVideo {
          mediaType {
            title
          }
          url
        }

        modelYear {
          year
          fuel
          katashiki
          testDrive
          disabled360

          colorWithPrice:vehicleColors {
            price
            color {
              name
              rgb
              code
            }
          }

          specs {
            contents {
              title
              description

              icon {
                url
                title

                mediaType {
                  title
                }
              }
            }
          }

          medias {
            tag

            media {
              url
              title

              mediaType {
                title
              }
            }
          }
        }
      }
    }
  }`;
};

/**
 * Query to find specific data from Vehicle by Page
 * Expect 'pageId' as variable
 *
 * @returns Promise<string>
 */
const findSpecificVehicleDataByPage = () => {
  return `query vehicle($pageId: String!) {
	vehicleByPage (pageId: $pageId) {
		name
		id

		page {
			fullpath
		}

		mainVersion {
			modelYear {
				year
			}
		}
	}
  }`;
};

function getVersionMedias(vehicle, versionId?: string) {
  // special case
  if (versionId === 'main') {
    return vehicle?.mainVersion?.modelYear?.medias || [];
  }

  if (!vehicle?.versions || !vehicle?.versions?.length) {
    return [];
  }

  // filter-off not available year
  const versions = vehicle.versions.filter((version) => {
    return version?.modelYear?.year;
  });

  // sort versions by model year
  versions.sort((a, b) => {
    return a.modelYear.year < b.modelYear.year ? 1 : -1;
  });

  // if empty versionId, we use the most recent version year
  if (!versionId) {
    return versions[0]?.modelYear?.medias || [];
  }

  const targetVersion = versions?.find((version) => version?.id === versionId);
  if (!targetVersion) {
    return [];
  }

  return targetVersion?.modelYear?.medias || [];
}

/**
 * Get medias from vehicle by its type
 *
 * @param vehicle structure from API
 * @param versionId id of wanted version. If null will get the latest year
 * @param tag one of know type:
 *  - logo
 *  - banner-image
 *  - 360
 *  - 360:desk
 *  - 360:desk:[color.code]
 *  - gallery
 *  - display
 *
 */
function getMediaByType(vehicle, versionId, tag) {
  const medias = getVersionMedias(vehicle, versionId);

  return medias.filter((media) => media.tag?.startsWith(tag));
}
/**
 * Transform API data into a simple version to page of Vehicle
 *
 * @param vehicle structure from API
 * @param locale string 'pt-BR' or 'es-AR'
 * @returns compact version with displayable data
 */
function getDisplayData(vehicle, locale = 'pt-BR') {
  const versionsData = [];

  const mainVersionId = vehicle?.mainVersion?.id || 'main';
  const pdfUrl = vehicle?.pdf_url;

  const vehicleExternalUrl = vehicle?.vehicleExternalUrl || 'https://e.toyota.com.ar/inventory';

  const modelLogo = getMediaByType(vehicle, mainVersionId, 'logo')?.[0]?.media;
  const emptyMedia = { url: '', title: '' };

  const linksList = (locale) => {
    if (String(locale).toLowerCase() === 'pt-br') {
      return [
        { label: 'build_your', url: `${vehicle?.page?.fullpath}/monte` },
        { label: 'offers', url: 'https://www.ofertastoyota.com.br/' },
        { label: 'rent_with_kinto', url: 'https://www.kintomobility.com.br' }
      ];
    }

    return [
      { label: 'online_quote', url: 'https://e.toyota.com.ar/inventory' },
      { label: 'purchase_options', url: '/compra' },
      { label: 'useds', url: 'https://www.toyotausados.com.ar/home' },
      { label: 'rent_with_kinto', url: 'https://www.kinto-mobility.com.ar' }
    ];
  };

  const galleries = {
    internal: getMediaByType(vehicle, mainVersionId, 'gallery:internal')?.map((v) => v?.media) || [],
    external: getMediaByType(vehicle, mainVersionId, 'gallery:external')?.map((v) => v?.media) || []
  };

  if (!vehicle?.versions) {
    console.error(
      `Falha ao tentar formatar dados de exibição do modelo ${vehicle.name} em ${locale}. Não possui versões.`
    );
    return {
      id: vehicle?.id,
      url_pdf: pdfUrl,
      vehicleExternalUrl: vehicleExternalUrl,
      name: vehicle?.name,
      fullpath: vehicle?.page?.fullpath,
      logo: {
        url: '',
        alt: ''
      },
      banner: {
        desktop: emptyMedia,
        mobile: emptyMedia
      },
      linksList: linksList(locale),
      galleries: galleries,
      versions: versionsData
    };
  }

  vehicle.versions.forEach((version) => {
    if (!version?.status || !version?.modelYear) {
      return;
    }

    const modelName = vehicle?.name;

    const fullpath = vehicle?.page?.fullpath;

    const display = version?.modelYear?.colorWithPrice?.map((colorWithPrice) => {
      return {
        name: colorWithPrice.color.name,
        rgb: colorWithPrice.color.rgb,
        code: colorWithPrice.color.code,
        price: colorWithPrice.price,
        installmentValue: null,
        installments: null,
        downValue: null,
        residualValue: null,
        city: null,
        thumbHoverImages:
          formatThumbHoverMedia(vehicle, version.id, [
            `model:desk:${colorWithPrice.color.code}`,
            `model:deskhover:${colorWithPrice.color.code}`
          ]) || [],
        thumbHoverMobImages:
          formatThumbHoverMedia(vehicle, version.id, [`model:mob:${colorWithPrice.color.code}`]) || [],
        spriteDesktop:
          getMediaByType(vehicle, version.id, `360:desk:${colorWithPrice.color.code}`)?.[0]?.media?.url || '',
        spriteMobile: getMediaByType(vehicle, version.id, `360:mob:${colorWithPrice.color.code}`)?.[0]?.media?.url || ''
      };
    });

    const specs = version?.modelYear?.specs?.map((spec) => {
      const specContent = spec?.contents?.[0];

      return {
        title: specContent?.title,
        content: specContent?.description,
        media: {
          url: specContent?.icon?.url,
          alt: specContent?.icon?.title,
          type: specContent?.icon?.mediaType?.title
        }
      };
    });

    versionsData.push({
      id: version.id,
      pdf_url: pdfUrl,
      vehicleExternalUrl: vehicleExternalUrl,
      model: modelName ? modelName : '',
      fullpath: fullpath || null,
      name: version.name,
      code: version?.code || null,
      year: version?.modelYear?.year,
      fuel: version?.modelYear?.fuel || null,
      katashiki: version?.modelYear?.katashiki || null,
      testDrive: version?.modelYear?.testDrive || false,
      disabled360: version?.modelYear?.disabled360 || false,
      hidePrice: version?.hidePrice || false,
      motorVideo: version?.motorVideo || false,
      kintoOnePersonal: version?.kintoOnePersonal || false,
      kintoOnePersonalLink: version?.kintoOnePersonalLink,
      display: display,
      specs: specs || null
    });
  });

  return {
    id: vehicle.id,
    url_pdf: pdfUrl,
    vehicleExternalUrl: vehicleExternalUrl,
    name: vehicle.name,
    fullpath: vehicle?.page?.fullpath,
    logo: {
      url: modelLogo?.url || '',
      alt: modelLogo?.title || ''
    },
    banner: {
      desktop: getMediaByType(vehicle, mainVersionId, 'banner-image:desk')?.[0]?.media || emptyMedia,
      mobile: getMediaByType(vehicle, mainVersionId, 'banner-image:mob')?.[0]?.media || emptyMedia
    },
    linksList: linksList(locale),
    galleries: galleries,
    versions: versionsData
  };
}

/**
 * format medias for thumbHover format
 *
 * @param medias vehicle version medias
 *
 * array data like:
 * [
 *  {
 *    url: '',
 *    alt: '',
 *    type: '',
 *  },
 * ]
 */
function formatThumbHoverMedia(vehicle, versionId, tags) {
  const formatedMedias = [];

  tags.map((tag) => {
    const images = getMediaByType(vehicle, versionId, tag);

    images.map((item) => {
      formatedMedias.push({
        url: item.media.url,
        alt: item.media.title,
        type: item.media.mediaType?.title
      });
    });
  });

  return formatedMedias;
}

const Vehicle = {
  getAllVehicles,
  getAllVehiclesWithVersions,
  getVehiclesByCategory,
  findByPage,
  getMediaByType,
  getDisplayData,
  getVehicle,
  getVehiclesByCategoryId,
  getVehiclesByCategoryForDisplay,
  getAllVehiclesWithPage,
  findSpecificVehicleDataByPage
};

export default Vehicle;
