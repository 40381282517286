import AnchorLink from 'components/atoms/anchor-link';
import Button from 'components/atoms/button';
import Title from 'components/atoms/title';
import React, { useState } from 'react';
import i18n from 'utils/i18n';
import { currency } from 'utils/functions';

// loading the sass style fot the component
import css from './styles.module.scss';
import Viwer360 from 'components/molecules/viwer-360';
import ModelLegalText from 'components/molecules/model-legal-text';
import ThumbHover from 'components/atoms/thumb-hover';
import { useMediaQuery } from 'react-responsive';

/**
 * Molecule CarDetailSimulation
 *
 * <!-- TODO: add a description here! -->
 */
function CarDetailSimulation(props) {
  // const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.car-detail-simulation');

  const { className = '', title = null, version, locale, templateData } = props;

  const [selectedColor, setSelectedColor] = useState(version?.display?.[0]);
  const country = String(locale).toLowerCase().substring(3);
  const isTDB = country === 'br';
  const isTASA = country === 'ar';
  const currencySymbol = isTDB ? 'BRL' : 'USD';
  const use360 = !version?.disabled360;
  const repurchaseManual = '/docs/manual-de-recompra.pdf';
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const images =
    (isTabletOrMobile ? selectedColor?.thumbHoverMobImages : selectedColor?.thumbHoverImages) ||
    selectedColor?.thumbHoverImages;

  const baseUrl = templateData?.extraLinkTarget || '';
  const separator = baseUrl.includes('?') ? '&' : '?';
  const link = `${baseUrl}${separator}version=${version?.name}&color=${encodeURIComponent(selectedColor?.name)}`;

  return (
    <div className={`${css['molecule-car-detail-simulation-container']} ${className}`} data-grid>
      {title && (
        <div className={css['section-title-container']}>
          <Title variant={2}>
            <strong className={css['bold']}>{title}</strong>
          </Title>
        </div>
      )}

      <div className={css['vehicle-content']}>
        {use360 ? (
          <Viwer360
            key={selectedColor?.spriteDesktop}
            spritesheet={selectedColor?.spriteDesktop}
            spritesheetMob={selectedColor?.spriteMobile}
          />
        ) : (
          <ThumbHover image={images} />
        )}

        <div className={css['color-selection']}>
          <div className={css['color-selection__content']}>
            <p className={css['color-selection__title']}> {t('select_color')} </p>
            <ul className={css['color-selection__list']}>
              {version?.display?.length > 0 &&
                version?.display?.map((color, index) => {
                  return (
                    <li key={`car-version-color-${index}`} className={css['color-selection__item']}>
                      <button
                        key={index}
                        className={css['color-selection__circle']}
                        data-is-selected={selectedColor?.code === color?.code ? true : undefined}
                        style={color.name ? { backgroundColor: color.rgb } : undefined}
                        onClick={(_) => setSelectedColor(color)}
                        title={color?.name}
                      ></button>
                    </li>
                  );
                })}
            </ul>
          </div>

          {selectedColor?.name && (
            <div className={css['color-selection__activated']}>{selectedColor?.name ? selectedColor?.name : null}</div>
          )}
        </div>
      </div>

      <div className={css['conditions-content']}>
        <div className={css['full-price']}>
          {!!selectedColor?.price && !version?.hidePrice ? (
            <>
              <span className={css['full-price__label']}>
                {t('price_title')} {isTASA && <sup>1</sup>}
              </span>
              <span className={css['full-price__value']} data-testid="car-price">
                {currency(selectedColor?.price, locale, currencySymbol)}
              </span>
              {isTDB && <span className={css['full-price__extra']}>{t('base_price_brasilia')}</span>}
            </>
          ) : (
            <span className={css['full-price__label']}> {t('check_availability')} </span>
          )}

          {!!version?.fullpath && (
            <Button className={css['full-price__action']} size={'large'} link={link}>
              {templateData?.extraLinkLabel || t('want_it')}
            </Button>
          )}
        </div>

        {isTDB &&
          !version?.hidePrice &&
          selectedColor?.installments &&
          selectedColor?.installmentValue &&
          selectedColor?.downValue &&
          selectedColor?.residualValue && (
            <>
              <hr className={css['conditions-content__divider']} />

              <div className={css['loan-info']}>
                <div className={css['loan-info__details']}>
                  <img
                    className={css['loan-info__logo']}
                    src="/images/logos/logo-ciclo-toyota.png"
                    alt="ciclo toyota"
                  />

                  <div className={css['loan-info__block']}>
                    <p className={css['loan-info__block-content']}>
                      <span>
                        <strong>{selectedColor?.installments}</strong> {t('installments_of')}
                      </span>
                      <span>
                        <strong className={css['loan-info__emphasis']}>
                          {currency(selectedColor?.installmentValue, locale, currencySymbol)}
                        </strong>
                      </span>
                    </p>
                    <span></span>
                    <span className={css['loan-info__block-plus']}>+</span>
                  </div>

                  <div className={css['loan-info__block']}>
                    <p className={css['loan-info__block-content']}>
                      <span>{t('entry_of')}</span>
                      <span>
                        <strong className={css['loan-info__emphasis']}>
                          {currency(selectedColor?.downValue, locale, currencySymbol)}
                        </strong>
                      </span>
                    </p>
                    <span></span>
                    <span className={css['loan-info__block-plus']}>+</span>
                  </div>

                  <div className={css['loan-info__block']}>
                    <p className={css['loan-info__block-content']}>
                      <span>{t('final_installment')}</span>
                      <span className={css['loan-info__emphasis']}>
                        <strong>{currency(selectedColor?.residualValue, locale, currencySymbol)}</strong>
                      </span>
                      <span>
                        <small className={css['loan-info__used_as_entry']}>{t('your_used_as_entry')}</small>
                      </span>
                    </p>
                  </div>
                </div>
                <div className={css['loan-info__actions']}>
                  <ModelLegalText
                    vehicleName={version?.model}
                    modelName={version?.name}
                    colorName={selectedColor?.name}
                    installmentsValue={selectedColor?.installmentValue}
                    btbData={selectedColor?.btbData}
                  />
                  <AnchorLink
                    size="small"
                    target="_blank"
                    rel="noreferrer"
                    link={repurchaseManual}
                    linkLabel={t('buyback_manual')}
                  />

                  {!!version?.fullpath && (
                    <Button
                      color="ghost-2"
                      size={'large'}
                      link={`${version?.fullpath}/monte?version=${version?.name}&color=${encodeURIComponent(
                        selectedColor?.name
                      )}&state=simule`}
                      className={css['loan-info__cta']}
                    >
                      {t('simulate_your_cycle')}
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}

        {!version?.hidePrice &&
          !!selectedColor?.price &&
          !!version?.kintoOnePersonal &&
          !!version?.kintoOnePersonalLink?.length && (
            <>
              <hr className={css['conditions-content__divider']} />
              <div className={css['kinto-info']}>
                <img width={220} height={104} src="/images/logos/logo-kinto.svg" alt="KINTO ONE PERSONAL" />
                <Button
                  color={'blue'}
                  size={'large'}
                  link={`${version?.kintoOnePersonalLink}`}
                  className={css['kinto-info__cta']}
                >
                  {t('sign_it')}
                </Button>
              </div>
            </>
          )}
      </div>
    </div>
  );
}

export default CarDetailSimulation;
