import React, { useState, useEffect } from 'react';
import ActionNoticeApps from 'components/molecules/action-notice-apps';
import AnchorLink from 'components/atoms/anchor-link';
import Button from 'components/atoms/button';
import Modal from 'components/molecules/simple-modal';
import FormSelect from 'components/atoms/form-select';
import { IconPinMap, IconCheck } from 'components/icons';
import MapInformation from 'components/molecules/map-information';
import Title from 'components/atoms/title';
import Wrapper from 'components/atoms/wrapper';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { updateURLParameter, removeCityAndStateParamsFromURL } from 'utils/forms';

import i18n from 'utils/i18n';
import { useServiceAppointment, ACTIONS } from 'providers/service-appointment';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule ServiceAppointmentDealersStep
 *
 * <!-- TODO: add a description here! -->
 */
function ServiceAppointmentDealersStep(props) {
  const t = i18n.useTranslations('components.service-appointment-dealers-step');

  const { className = '', dealers = [], userRegisterData = {}, locale, onPreviousStep, onNextStep, ...other } = props;

  const validationSchema = yup
    .object()
    .shape({
      state: yup.string().notOneOf(['placeholder']),
      city: yup.string().notOneOf(['placeholder'])
    })
    .required();

  const { register, setValue, watch } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { state: 'placeholder', city: 'placeholder' }
  });

  const { state, dispatch } = useServiceAppointment();

  const mapZoom = 1;
  const watchCity = watch('city');
  const watchState = watch('state');

  const [markers, setMarkers] = useState([]);
  const [citiesFromStates, updateCitiesFromStates] = useState([]);
  const [goToCurrentPosition, setGoToCurrentPosition] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const [favoriteDealers, setFavoriteDealers] = useState(null);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDealerId, setSelectedDealerId] = useState(state?.dealerId);
  const selectedDealerContextData = dealers?.find((dealer) => dealer.id === state.dealerId);
  const listStates = [...new Set(dealers.map((state) => state.address.state))].sort();
  const [isSlotsError, setIsSlotsError] = useState(null);
  const [loadingSlots, setLoadingSlots] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const serviceAppointmentContentData = {
    messageInstallApps: t('message_install_apps') || null,
    linksApp: [
      {
        href: t('google_play_URL') || null,
        imgSrc: '/images/google_play.svg',
        alt: 'Google Play',
        className: 'google_play'
      },
      {
        href: t('app_store_URL') || null,
        imgSrc: '/images/app_store.svg',
        alt: 'App Store',
        className: 'app_store'
      }
    ],

    isAppointmentStep: true
  };

  useEffect(() => {
    initializeComponent();
    fetchFavoriteDealers();
    handleCheckDataAndProceed();
  }, []);

  useEffect(() => {
    if (state.dealerId === null) {
      disableNextSteps();
    }
    setSelectedDealerId(state?.dealerId);
  }, [state?.dealerId]);

  useEffect(() => {
    if (watchState && watchState !== 'placeholder') {
      updateURLParameter('state', watchState);
      loadCitiesList(watchState);
    }
  }, [watchState]);

  function loadCitiesList(state) {
    const dealersFromState = dealers?.filter((dealer) => dealer?.address?.state.toUpperCase() === state?.toUpperCase());

    if (state !== 'placeholder') {
      setMarkers(dealersFromState);
    }

    const uniqueCitiesList = Array.from(
      new Set(dealersFromState.map((dealer) => dealer.address.city.toUpperCase()))
    ).sort();

    updateCitiesFromStates(uniqueCitiesList);
  }

  useEffect(() => {
    if (watchCity && watchCity !== 'placeholder') {
      updateURLParameter('city', watchCity);
      const dealersFromCity = dealers?.filter(
        (dealer) =>
          dealer?.address?.state.toUpperCase() === watchState?.toUpperCase() && dealer?.address?.city === watchCity
      );
      setMarkers(dealersFromCity);
    }
  }, [watchCity]);

  useEffect(() => {
    let timer;

    if (locationError) {
      timer = setTimeout(() => {
        setLocationError(null);
      }, 8000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [locationError]);

  const initializeComponent = () => {
    const url = new URL(location.href);
    const params = url.searchParams;

    const paramDealerState = params.get('state');
    const city = params.get('city');

    if (paramDealerState !== null && paramDealerState !== '') {
      setValue('state', paramDealerState.toString());

      if (city !== null && city !== '') {
        setValue('city', city.toString());
      } else {
        const dealersFromState = dealers?.filter(
          (dealer) => dealer?.address?.state.toUpperCase() === paramDealerState.toUpperCase()
        );
        setMarkers(dealersFromState);
      }
    } else {
      setMarkers(dealers);
    }

    if (selectedDealerContextData) {
      setValue('state', selectedDealerContextData?.address?.state.toString());
      setValue('city', selectedDealerContextData?.address?.city.toString());
    }
  };

  const disableNextSteps = () => {
    const updatedListSteps = state.listSteps.map((step) => {
      if (step.type === 'PRICE_DATE' || step.type === 'ATTENDEE') {
        return { ...step, disabled: true };
      }
      return step;
    });

    dispatch({ type: ACTIONS.UPDATE_LIST_STEP, payload: updatedListSteps });
  };

  const cleanKmValue = (km) => {
    const cleanedKm = km.replace(/[^\d.]/g, '');
    return cleanedKm;
  };

  async function fetchFavoriteDealers() {
    try {
      const response = await fetch(`/api/favorite-dealers/get-favorite-dealers`);

      if (!response.ok) {
        throw new Error(`Failed to fetch favorite dealers: ${response.status} - ${response.statusText}`);
      }

      const responseData = (await response.json())?.favoriteDealers || [];
      const favoriteDealersIds = responseData.map(({ id }) => id);

      const updatedFavoriteDealers = dealers
        .filter(({ id }) => favoriteDealersIds.includes(id))
        .map((currentDealer) => ({ ...currentDealer, isFavorite: true }));

      const firstFavoriteDealer = updatedFavoriteDealers[0] || null;

      setFavoriteDealers(firstFavoriteDealer);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchSlots = async () => {
    setLoadingSlots(true);

    const cleanedKm = cleanKmValue(state.km) || '';

    const body = {
      customerVehicleId: state?.customerVehicleId,
      dealerId: selectedDealerId,
      km: cleanedKm,
      hasRecall: state.hasRecall === 'true',
      hasWash: state.hasWash || false,
      additionalServices: state.additionalServices || [],
      others: state.others || '',
      contact: {
        firstName: userRegisterData?.name,
        lastName: userRegisterData?.lastName,
        email: userRegisterData?.email,
        dni: userRegisterData?.dni,
        mobile: userRegisterData?.cellphoneNumber
      }
    };

    try {
      const response = await fetch('/api/appointment/get-slots', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      const messageErrorDealers = t('message_error_dealers');

      if (!response.ok) {
        const errorData = await response?.json();

        console.error('API Error Response:', errorData);

        const error = {
          message: messageErrorDealers
        };
        throw error;
      }

      const responseData = await response.json();

      const isOthersEmpty = !state.others || state.others.trim() === '';

      if (Array.isArray(responseData) && responseData.length === 0 && isOthersEmpty) {
        const error = {
          message: messageErrorDealers
        };
        throw error;
      }

      return responseData;
    } catch (error) {
      console.error(error);
      handleApiError(error);
    } finally {
      setLoadingSlots(false);
    }
  };

  const handleApiError = (error) => {
    const { message } = error;

    const defaultError = {
      messageError: message || t('message_error_retry'),
      buttonAction: () => {
        disableDealerIfNoSlots(selectedDealerId);
      },
      buttonLabel: t('button_label_selected_new_dealer') || t('button_label_retry')
    };

    const { messageError, buttonAction, buttonLabel } = defaultError;
    setIsSlotsError({
      modalTitle: messageError,
      modalButtonAction: buttonAction,
      modalButtonLabel: buttonLabel
    });
  };

  const disableDealerIfNoSlots = (dealerId) => {
    dispatch({ type: 'SET_SELECTED_DEALER_ID', payload: null });
    dispatch({
      type: ACTIONS.DISABLE_DEALERS_WITHOUT_SLOTS,
      payload: [dealerId]
    });
    setIsSlotsError(null);
    setIsDisabled(true);
    setIsModalOpen(false);
  };

  const onChangeState = (event) => {
    const stateValue = event.target.value;
    setValue('city', 'placeholder');
    updateURLParameter('city', '');
    setValue('state', stateValue.toString());
  };

  const onChangeCity = (event) => {
    const cityValue = event.target.value;
    setValue('city', cityValue.toString());
  };

  const capitalizeFirstLetter = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : '';
  };

  const formatAddress = (address) => {
    if (!address) {
      return '';
    }

    const { street, number, city, state } = address;
    return `${capitalizeFirstLetter(street)} ${capitalizeFirstLetter(number)} - ${capitalizeFirstLetter(
      city
    )}, ${capitalizeFirstLetter(state)}`;
  };

  const handleUseMyLocation = async () => {
    setLoadingLocation(true);

    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setGoToCurrentPosition({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            });
          },
          (error) => {
            setLocationError(t('unable_to_access_location'));
            console.log(error);
          }
        );
      } else {
        setLocationError(t('message_geolocation_not_supported'));
      }
    } catch (error) {
      console.error('Error getting user location:', error);
    } finally {
      setLoadingLocation(false);
    }
  };

  const handleSelectDealer = (dealerId) => {
    if (dealerId === selectedDealerId) {
      setSelectedDealerId(null);
    } else {
      setIsDisabled(false);
      setSelectedDealerId(dealerId);
    }
  };

  const handleConfirmAppointment = async (dealerId) => {
    setIsDisabled(false);
    if (selectedDealerId && !isLastStep) {
      const slotsData = await fetchSlots();
      if (Array.isArray(slotsData) && slotsData.length > 0) {
        dispatch({ type: 'SET_SELECTED_DEALER_ID', payload: dealerId });
        dispatch({ type: ACTIONS.SET_SLOTS, payload: slotsData });
        setIsDisabled(false);
        removeCityAndStateParamsFromURL();
        return onNextStep();
      } else {
        setIsDisabled(true);
      }
    }
    if (isLastStep) {
      return setIsModalOpen(true);
    }
  };

  const handleLastStepConfirmation = async () => {
    try {
      const slots = await fetchSlots();
      if (slots) {
        dispatch({ type: 'SET_FINAL_STEP_COMPLETED', payload: true });
        removeCityAndStateParamsFromURL();
        onNextStep();
      }
    } catch (error) {
      console.error('Error sending data', error);
    }
  };

  const handleCheckDataAndProceed = () => {
    const othersOrAvailableForAppointments = state?.others || state?.availableForAppointments === false;
    const dealershipStepIndex = state.listSteps.findIndex((step) => step.type === 'DEALERSHIP');
    const isFinalDealershipStep = dealershipStepIndex === state.listSteps.length - 1;

    setIsLastStep(othersOrAvailableForAppointments && isFinalDealershipStep);
  };

  const closeModal = () => setIsModalOpen(false);

  return (
    <Wrapper className={`${css['molecule__service-appointment-dealers-step-container']} ${className}`} {...other}>
      <div className={css['dealers-step-container']}>
        <div className={css['dealers-step-container_form']}>
          <Title className={css['title-section']}>{t('title_section_form')}</Title>
          <div className={css['form_filtered']}>
            <div className={css['form_filtered-input']}>
              <FormSelect
                displayLabel={false}
                displayError={false}
                id="state"
                placeholder={t('placeholder_state')}
                className={`${css['select-input']} ${watchState === 'placeholder' && css['placeholder-selected']}`}
                dataRegister={register('state')}
                onChange={onChangeState}
              >
                {listStates?.length > 0 ? (
                  listStates?.map((state, index) => {
                    return (
                      <option key={index} value={state.toUpperCase()}>
                        {state.toUpperCase()}
                      </option>
                    );
                  })
                ) : (
                  <option>{t('placeholder_state')}</option>
                )}
              </FormSelect>

              <FormSelect
                displayLabel={false}
                displayError={false}
                id="city"
                placeholder={t('placeholder_city')}
                className={`${css['select-input']} ${watchCity === 'placeholder' && css['placeholder-selected']}`}
                dataRegister={register('city')}
                value={watchCity}
                onChange={onChangeCity}
              >
                {citiesFromStates.length > 0 ? (
                  citiesFromStates?.map((city, index) => {
                    return (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    );
                  })
                ) : (
                  <option>{t('placeholder_city')}</option>
                )}
              </FormSelect>
            </div>
            <AnchorLink
              className={css['anchor-location']}
              size="small"
              hasArrow={false}
              onClick={handleUseMyLocation}
              linkLabel={
                <>
                  {t('use_my_location')}
                  <IconPinMap type="location" className={css['icon-pin-location']} />
                </>
              }
            />
          </div>

          {loadingLocation && (
            <div className={`loading-indicator ${css['loading-indicator']}`}>{t('loading_my_location')}</div>
          )}
        </div>

        {favoriteDealers && (
          <div className={css['dealers-step-container_favorite']}>
            <Title className={css['title-section']}>{t('title_section_favorite')}</Title>
            <div
              className={`${css['favorite-card']} ${
                !favoriteDealers?.availableForAppointment && css['web-unavailable']
              }`}
            >
              <div className={`${css['favorite-card-content']}`}>
                <div className={css['favorite-card-content-header']}>
                  <IconPinMap className={css['favorite-card-content-icon']} />
                  <span className={css['favorite-card-content-name']}>
                    {capitalizeFirstLetter(favoriteDealers?.name)}
                  </span>
                </div>
                <p className={css['favorite-card-content-address']}>{formatAddress(favoriteDealers?.address)}</p>
              </div>
              {favoriteDealers?.availableForAppointment && (
                <Button
                  className={css['favorite-card-button']}
                  color={selectedDealerId === favoriteDealers.id ? 'primary' : 'ghost-2'}
                  onClick={() => handleSelectDealer(favoriteDealers.id)}
                >
                  {selectedDealerId === favoriteDealers.id ? (
                    <>
                      {t('label_selected_dealer')}
                      <IconCheck className={css['button-icon']} />
                    </>
                  ) : (
                    t('label_select_dealer')
                  )}
                </Button>
              )}

              {!favoriteDealers?.availableForAppointment && (
                <ActionNoticeApps
                  messageContent={serviceAppointmentContentData?.messageInstallApps}
                  linksApp={serviceAppointmentContentData?.linksApp}
                />
              )}
            </div>
          </div>
        )}
      </div>

      <MapInformation
        data-id={'map-service-appointment'}
        typeInfo="appointment"
        locale={locale}
        markers={markers}
        selectedCity={watchCity}
        selectedState={watchState}
        selectedZoom={mapZoom}
        centerOffset={-0.012}
        goToCurrentPosition={goToCurrentPosition}
        onConfirmAppointment={handleSelectDealer}
        selectedDealerId={selectedDealerId}
        selectedDealerIdContext={selectedDealerContextData?.id}
        serviceAppointmentContentData={serviceAppointmentContentData}
        disabledDealers={state?.disabledDealers}
      />

      {locationError && (
        <span id="toast" className={css[locationError ? 'toast' : 'hidden']}>
          {locationError}
        </span>
      )}

      <div className={css['dealers-step-container_confirm']}>
        <Button
          className={css['confirm_button']}
          onClick={() => handleConfirmAppointment(selectedDealerId)}
          disabled={
            !selectedDealerId ||
            (selectedDealerId === state?.dealerId && !isLastStep && !isDisabled) ||
            loadingSlots ||
            isSlotsError ||
            isDisabled
          }
        >
          {isLastStep
            ? t('label_confirm_reservation')
            : loadingSlots
            ? t('sending_confirm_reservation')
            : t('label_confirm')}
        </Button>
      </div>

      {isModalOpen && (
        <Modal
          className={css['modal-container']}
          isOpen={isModalOpen}
          onClose={closeModal}
          disabledClose={loadingSlots}
        >
          <Title variant={2}>{t('title_modal')}</Title>
          <div className={css['modal-container-content']}>{t('content_modal')}</div>
          <div className={css['modal-container-buttons']}>
            <Button onClick={closeModal} disabled={loadingSlots} color="ghost-2">
              {t('label_button_cancel')}
            </Button>
            <Button onClick={() => handleLastStepConfirmation()} disabled={loadingSlots}>
              {t('label_button_confirm')}
            </Button>
          </div>
        </Modal>
      )}

      {isSlotsError && (
        <Modal className={css['modal-container']} isOpen={isSlotsError} disabledClose={loadingSlots}>
          {isSlotsError?.modalTitle && <Title variant={2}>{isSlotsError?.modalTitle} </Title>}
          {isSlotsError?.modalButtonAction && isSlotsError?.modalButtonLabel && (
            <div className={css['modal-container-buttons']}>
              <Button onClick={isSlotsError?.modalButtonAction} disabled={loadingSlots}>
                {isSlotsError?.modalButtonLabel}
              </Button>
            </div>
          )}
        </Modal>
      )}
    </Wrapper>
  );
}

export default ServiceAppointmentDealersStep;
