import React from 'react';
// import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

import Button from 'components/atoms/button';
import AnchorLink from 'components/atoms/anchor-link';
import CarListModels from 'components/molecules/car-list-models';

import Wrapper from 'components/atoms/wrapper';

/**
 * Molecule ActionBannerItem
 *
 * An slide for the action banner
 */
function ActionBannerItem(props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.action-banner-item');

  const {
    className = '',
    children,
    title,
    logo = null,
    description,
    cta = null,
    containerSize = null,
    link = null,
    bannerImage = null,
    linksList = null,
    titleOnly = null,
    removeMask = null,
    variant = null,
    marginTop = null,
    marginBottom = null,
    ...other
  } = props;

  const ctaSize = containerSize === 'medium' ? 'small' : 'large';

  return (
    <>
      <Wrapper
        className={`${css['banner-slider']} ${className}`}
        {...other}
        data-title-only={titleOnly}
        data-container-size={containerSize}
        data-variant={variant}
        data-margin-top={marginTop}
        data-margin-bottom={marginBottom}
      >
        <div className={css['poster-wrapper']}>
          <picture>
            <source srcSet={bannerImage?.desktop} media="(min-width: 1080px)" />
            <img className={css['poster-wrapper__poster']} src={bannerImage?.mobile} alt={bannerImage?.alt || ''} />
          </picture>
        </div>
        {linksList ? (
          <>
            <div className={css['content']}>
              <div className={`${css['info-container']} ${css['info-container--list']}`} data-remove-mask={removeMask}>
                <CarListModels logo={logo} linksList={linksList} />
              </div>
            </div>
          </>
        ) : (
          title &&
          title?.length > 0 &&
          title?.trim().length > 0 && (
            <div className={css['content']}>
              <div className={css['info-container']} data-title-only={titleOnly} data-remove-mask={removeMask}>
                <div className={css['title-description-group']}>
                  <h2 className={css['info-container__title']}>
                    {title}
                    {logo && <img src={logo?.url} alt={logo?.alt} />}
                  </h2>
                  {description && <p className={css['info-container__description']}>{description}</p>}
                </div>
                <div className={css['button-link-group']}>
                  {cta && (
                    <Button className={css['cta']} size={ctaSize} color="ghost" link={cta?.action}>
                      {cta?.text}
                    </Button>
                  )}
                  {link && (
                    <AnchorLink
                      className={css['anchor-link']}
                      linkLabel={link?.label}
                      link={link?.url}
                      size="small"
                      color="white"
                    />
                  )}
                </div>
              </div>
            </div>
          )
        )}
      </Wrapper>
    </>
  );
}

export default ActionBannerItem;
