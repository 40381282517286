export const formReasons = {
  'Información de producto': 'Información de producto',
  'Plan de ahorro': 'Plan de ahorro',
  Financiación: 'Financiación',
  'Test Drive': 'Test Drive',
  Posventa: 'Pos Venta'
};

const sendLeadToSalesforceGeneralEnquiry = () => {
  return `
    mutation sendLeadToSalesforceGeneralEnquiry (
      $consultReason : String
      $firstName : String
      $lastName : String
      $email : String
      $telephone : String
      $comments : String
  ) {
      sendLead(
        saveLeadInput : {
          consultReason : $consultReason
          firstName : $firstName
          lastName : $lastName
          email : $email
          telephone : $telephone
          comments : $comments
        },
        locale : "es-ar"
    )
  }`;
};

const mutationContactCustomerService = () => {
  return `
  mutation (
    $locale: String!
    $name: String!
    $email: String!
    $doc: String!
    $vehicleChassi: String
    $vehicleKM: String
    $subject: String!
    $reason: String
    $description: String!
    $phone: String!
  ) {
    contactCustomerService(
      locale: $locale,
      input: {
        name: $name,
        email: $email,
        doc: $doc,
        vehicleChassi: $vehicleChassi,
        vehicleKM: $vehicleKM,
        subject : $subject,
        reason: $reason,
        description: $description,
        phone: $phone
      }
    )
  }`;
};

const mutationContactCustomerServiceTDV = () => {
  return `
    mutation ($locale: String!, $productId: String!, $data: TdvLeadInput!) { 
    contactSailsforceService ( 
    locale: $locale, 
    productId: $productId, 
    input: $data
    )}
  `;
};

const SalesForce = {
  sendLeadToSalesforceGeneralEnquiry,
  mutationContactCustomerService,
  mutationContactCustomerServiceTDV
};

export default SalesForce;
