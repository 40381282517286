import React, { useCallback, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import getConfig from 'next/config';
import Script from 'next/script';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import i18n from 'utils/i18n';
import { captchaToken } from 'utils/captcha/token';
import {
  hasNoAccentedCharacters,
  hasNoNumbers,
  hasNoSpaces,
  hasNoSpecialCharacters,
  phoneMaskTDV
} from 'utils/validations';
import { removeSpecialCharacters, getCitiesFromDealers, getDealersByCity } from 'utils/forms';
import AnchorLink from 'components/atoms/anchor-link';
import Button from 'components/atoms/button';
import FormCheckbox from 'components/atoms/form-checkbox';
import FormInput from 'components/atoms/form-input';
import FormSelect from 'components/atoms/form-select';
import FormTextarea from 'components/atoms/form-text-area';
import Section from 'components/utils/section';
import SectionHeader from 'components/molecules/section-header';
import SuccessSubmitForm from 'components/molecules/success-submit-form';
import Wrapper from 'components/atoms/wrapper';
import Title from 'components/atoms/title';
// loading the sass style fot the component
import css from './styles.module.scss';

const { publicRuntimeConfig } = getConfig();
/**
 * Molecule ContactForm
 *
 * The services page contact form
 */
function ContactForm(props) {
  // const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.contact-form');
  const router = useRouter();

  const {
    className = '',
    locale,
    headerSpacing,
    title,
    description,
    titleSuccess,
    contentSuccess,
    titleError,
    contentError,
    children,
    contactFormType,
    models,
    dealers,
    states,
    ...other
  } = props;

  const [listCities, setListCities] = useState([]);
  const [listDealers, setListDealers] = useState([]);
  const [isStatusComponent, setIsStatusComponent] = useState('default');
  const [isDisable, setIsDisable] = useState(false);

  const getSchemaConfigByType = () => {
    const baseSchema = {
      name: yup
        .string()
        .required(t('message_error_required_name'))
        .min(1, t('message_error_min_char_name'))
        .max(40, t('message_error_max_char_name'))
        .test('test-accented-name', t('message_error_accented_name'), (value) => hasNoAccentedCharacters(value))
        .test('test-special-char-name', t('message_error_special_char_name'), (value) => hasNoSpecialCharacters(value))
        .test('test-numeric-char-name', t('message_error_numeric_char_name'), (value) => hasNoNumbers(value))
        .test('test-space-char-name', t('message_error_space_char_name'), (value) => hasNoSpaces(value)),
      surname: yup
        .string()
        .required(t('message_error_required_surname'))
        .min(1, t('message_error_min_char_surname'))
        .max(80, t('message_error_max_char_surname'))
        .test('test-accented-surname', t('message_error_accented_surname'), (value) => hasNoAccentedCharacters(value))
        .test('test-special-char-surname', t('message_error_special_char_surname'), (value) =>
          hasNoSpecialCharacters(value)
        )
        .test('test-numeric-char-surname', t('message_error_numeric_char_surname'), (value) => hasNoNumbers(value))
        .test('test-space-char-surname', t('message_error_space_char_surname'), (value) => hasNoSpaces(value)),
      email: yup.string().required(t('message_error_required_email')).email(t('message_error_invalid_email')),
      phone: yup.string().required(t('message_error_required_phone')).min(11, t('message_error_min_char_phone')),
      state: yup
        .string()
        .notOneOf(['placeholder'], t('message_error_required_state'))
        .required(t('message_error_required_state')),
      city: yup
        .string()
        .notOneOf(['placeholder'], t('message_error_required_city'))
        .required(t('message_error_required_city')),
      dealerId: yup
        .string()
        .notOneOf(['placeholder'], t('message_error_required_dealerId'))
        .required(t('message_error_required_dealerId')),
      agreement: yup.boolean().oneOf([true], '')
    };

    switch (contactFormType) {
      case 'Presupuesto':
        return {
          ...baseSchema,
          model: yup
            .string()
            .notOneOf(['placeholder'], t('message_error_required_model_interest'))
            .required(t('message_error_required_model_interest'))
        };
      case 'Atención al cliente':
        return {
          ...baseSchema,
          comment: yup.string().required(t('message_error_required_message')),
          document: yup.string().when({
            is: (value) => !!value,
            then: (schema) =>
              schema
                .min(5, t('message_error_min_char_document'))
                .max(17, t('message_error_max_char_document'))
                .test('test-accented-document', t('message_error_accented_document'), (value) =>
                  hasNoAccentedCharacters(value)
                ),
            otherwise: (schema) => schema
          })
        };
      case 'Recall':
        return {
          ...baseSchema,
          VIN: yup.string().required(t('message_error_required_VIN')).min(10, t('message_error_min_char_VIN')),
          model: yup
            .string()
            .notOneOf(['placeholder'], t('message_error_required_model'))
            .required(t('message_error_required_model')),
          agreement: yup.boolean().oneOf([true], t('message_error_required_agreement')),
          document: yup.string().when({
            is: (value) => !!value,
            then: (schema) =>
              schema
                .min(5, t('message_error_min_char_document'))
                .max(17, t('message_error_max_char_document'))
                .test('test-accented-document', t('message_error_accented_document'), (value) =>
                  hasNoAccentedCharacters(value)
                ),
            otherwise: (schema) => schema
          })
        };
      default:
        return baseSchema;
    }
  };
  const validationSchemaConfig = () => {
    return getSchemaConfigByType();
  };

  const validationSchema = yup.object().shape(validationSchemaConfig());

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    clearErrors,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      vehicleColor: '',
      vehicleVersion: '',
      vehicleModel: ''
    }
  });

  const watchedState = watch('state');
  const watchedFields = watch();

  useEffect(() => {
    const isAnyFieldPlaceholder = Object?.values(watchedFields).some((value) => value === 'placeholder');
    setIsDisable(isAnyFieldPlaceholder);
  }, [watchedFields]);

  useEffect(() => {
    const data = router?.query;

    if (!data) {
      return;
    }

    if (contactFormType === 'Recall') {
      if (data?.chassi) {
        setValue('VIN', data.chassi);
      }

      if (data?.campaign) {
        const decodedData = decodeURIComponent(data.campaign);
        setValue('campaign', decodedData);
      }
      return;
    }

    if (contactFormType === 'Presupuesto' && data?.model && data?.version && data?.color) {
      const model = models.find((model) => model.name === data.model);
      const version = model.versions.find((version) => version.name === data.version);
      const color = version.modelYear.vehicleColors.find((color) => color.color.name === data.color);

      setValue('model', data.model);
      setValue('vehicleModel', model.vehicleExternalId);
      setValue('vehicleVersion', version.modelYear.clientVehicleId);
      setValue('vehicleColor', color.color.code);

      return;
    }
  }, [router]);

  function loadCities(selectedState) {
    const resultCities = getCitiesFromDealers(dealers, selectedState);
    setListCities(resultCities);
  }

  function loadDealers(selectedCity) {
    const filtered = getDealersByCity(dealers, selectedCity);
    setListDealers(filtered);
  }

  function onChangeState(e) {
    setValue('city', 'placeholder');
    setValue('dealerId', 'placeholder');
    loadCities(e.target.value);
  }

  function onChangeCity(e) {
    setValue('dealerId', 'placeholder');
    clearErrors('city');
    loadDealers(e.target.value);
  }

  function onChangeModel(e) {
    const modelValue = e.target.value;
    const model = models.find((model) => model.name === modelValue);

    setValue('vehicleModel', model?.vehicleExternalId);
    setValue('vehicleColor', model?.mainVersionExternalColor.code);
    setValue('vehicleVersion', model?.mainVersionCode);
  }

  function getFormType(contactFormType) {
    const typeMap = {
      Presupuesto: 'BUDGET',
      'Atención al cliente': 'CUSTOMER_SUPPORT',
      Recall: 'RECALL'
    };

    return typeMap[contactFormType] || 'UNKNOWN';
  }

  const onSubmit = async (data) => {
    setIsDisable(true);

    const captchaPayload = await captchaToken('CONTACT', publicRuntimeConfig?.G_RECAPTCHA_TOKEN || '');
    if (!captchaPayload) {
      console.debug('Falhou verificação do recaptcha');
      setIsStatusComponent('error');

      return false;
    }

    let updatedData;
    let url;

    const formatedPhone = removeSpecialCharacters(data?.phone).replace(/\s+/g, '');

    if (contactFormType === 'Presupuesto') {
      url = '/api/lead/lead-contact-customer-service-TDV';

      const dealerNumber = dealers.find((dealer) => dealer.id === data.dealerId);

      updatedData = {
        firstName: data.name,
        lastName: data.surname,
        codMovil: formatedPhone.substring(0, 4),
        mobile: formatedPhone.substring(4),
        email: data.email,
        dealer: dealerNumber.dn,
        state: data.state,
        model: data.vehicleModel || '',
        color: data.vehicleColor || '',
        version: data.vehicleVersion || '',
        locale: locale
      };
    } else {
      url = '/api/contact';

      updatedData = data;
      updatedData.captchaPayload = captchaPayload;
      updatedData.locale = locale;
      updatedData.type = getFormType(contactFormType);
      updatedData.phone = formatedPhone;

      delete updatedData.vehicleColor;
      delete updatedData.vehicleVersion;
      delete updatedData.vehicleModel;
    }

    const opts = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updatedData)
    };

    fetch(url, opts)
      .then((res) => {
        if (res.status === 200) {
          setIsDisable(false);
          resetForm(reset);
          setIsStatusComponent('success');

          if (contactFormType === 'Presupuesto') {
            router.replace(window.location.pathname);
          }

          return;
        }
        setIsDisable(false);
        setIsStatusComponent('error');
      })
      .catch((err) => {
        setIsDisable(false);
        console.log(err);
      });
  };

  const resetForm = useCallback(() => {
    reset();
  }, [reset]);

  function onBackForm() {
    setIsStatusComponent('default');
  }

  function onError(error) {
    console.log('Error: ', error);
  }

  return (
    <div className={`${css['molecule__contact-form-container']} ${className}`} {...other}>
      <Wrapper spacing={headerSpacing}>
        <Section className={css['success-container']}>
          <Script
            src={`https://www.google.com/recaptcha/enterprise.js?render=${publicRuntimeConfig.G_RECAPTCHA_TOKEN}`}
          ></Script>

          {isStatusComponent === 'success' && (
            <SuccessSubmitForm
              onBackForm={onBackForm}
              titleSuccess={titleSuccess ? titleSuccess : t('success_title')}
              contentSuccess={contentSuccess ? contentSuccess : t('success_content')}
              contentBackForm={t('content_back_form')}
              contentBackHome={t('content_back_home')}
            />
          )}

          {isStatusComponent === 'error' && (
            <SuccessSubmitForm
              onBackForm={onBackForm}
              titleSuccess={titleError ? titleError : t('error_title')}
              contentSuccess={contentError ? contentError : t('error_content')}
              contentBackForm={t('content_back_form')}
              contentBackHome={t('content_back_home')}
            />
          )}
          {isStatusComponent === 'default' && (
            <>
              <SectionHeader
                title={title ? title : t('title_form')}
                description={{
                  value: description ? description : t('description_form'),
                  textColor: 'gray-4'
                }}
              />
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className={css['form-container']}
                data-testid="form-submit"
              >
                {contactFormType === 'Presupuesto' && (
                  <div className={css['form-container__section']} data-grid-full-width data-grid-list="3">
                    <Title className={css['form-container__title']} variant={5} data-grid-full-width>
                      {t('select_model_interest')}
                    </Title>
                    <FormSelect
                      className={css['form-container__input']}
                      id="model"
                      placeholder={t('placeholder_model_interest')}
                      dataRegister={register('model')}
                      displayLabel={false}
                      dataErrors={errors['model']}
                      onChange={onChangeModel}
                    >
                      {models &&
                        models?.map((model, index) => {
                          return (
                            <option key={index} value={model.name}>
                              {model.name}
                            </option>
                          );
                        })}
                    </FormSelect>
                  </div>
                )}
                <div className={css['form-container__section']} data-grid-list="3">
                  <Title className={css['form-container__title']} variant={5} data-grid-full-width>
                    {t('title_section_one')}
                  </Title>

                  <FormInput
                    id="name"
                    className={css['form-container__input']}
                    label={t('label_user_name_field')}
                    placeholder={t('placeholder_user_name_field')}
                    dataRegister={register('name')}
                    dataErrors={errors['name']}
                    type="text"
                  />

                  <FormInput
                    id="surname"
                    className={css['form-container__input']}
                    label={t('label_user_surname_field')}
                    placeholder={t('placeholder_user_surname_field')}
                    dataRegister={register('surname')}
                    dataErrors={errors['surname']}
                    type="text"
                  />

                  {contactFormType !== 'Presupuesto' && (
                    <FormInput
                      id="document"
                      className={css['form-container__input']}
                      label={t('label_document_field')}
                      placeholder={t('placeholder_document_field')}
                      dataRegister={register('document')}
                      dataErrors={errors['document']}
                      type="text"
                    />
                  )}

                  <FormInput
                    id="email"
                    className={css['form-container__input']}
                    label={t('label_email_field')}
                    dataRegister={register('email')}
                    dataErrors={errors['email']}
                    type="text"
                    maxLength="200"
                    placeholder={t('placeholder_email_field')}
                  />

                  <FormInput
                    id="phone"
                    className={css['form-container__input']}
                    label={t('label_phone_field')}
                    dataRegister={register('phone')}
                    dataErrors={errors['phone']}
                    type="tel"
                    maxLength={t('max_lenght_phone')}
                    placeholder={t('placeholder_phone_field')}
                    masking={(event) => {
                      const value = event.target.value;
                      event.target.value = phoneMaskTDV(value);
                    }}
                  />

                  {contactFormType === 'Recall' && (
                    <div className={css['form-container__section']} data-grid-full-width data-grid-list="3">
                      <Title className={css['form-container__title']} variant={5} data-grid-full-width>
                        {t('title_section_three')}
                      </Title>
                      <FormInput
                        id="model"
                        className={css['form-container__input']}
                        label={t('label_model_field')}
                        dataRegister={register('model')}
                        dataErrors={errors['model']}
                        type="text"
                        maxLength="80"
                        placeholder={t('placeholder_model_field')}
                      />
                      <FormInput
                        id="VIN"
                        data-testid="doc-vehicle"
                        className={css['form-container__input']}
                        label={t('label_VIN_field')}
                        dataRegister={register('VIN')}
                        dataErrors={errors['VIN']}
                        type="text"
                        maxLength="20"
                        placeholder={t('placeholder_VIN_field')}
                      />
                    </div>
                  )}
                </div>

                <div className={css['form-container__section']} data-grid-full-width data-grid-list="3">
                  <Title className={css['form-container__title']} variant={5} data-grid-full-width>
                    {t('title_section_two')}
                  </Title>
                  <FormSelect
                    className={css['form-container__input']}
                    id="state"
                    label={t('label_state_field')}
                    placeholder={t('placeholder_state_field')}
                    onChange={onChangeState}
                    dataRegister={register('state')}
                    dataErrors={errors['state']}
                  >
                    {states &&
                      states?.map((state, index) => {
                        return (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        );
                      })}
                  </FormSelect>
                  <FormSelect
                    className={css['form-container__input']}
                    id="city"
                    label={t('label_city_field')}
                    placeholder={t('placeholder_city_field')}
                    onChange={onChangeCity}
                    dataRegister={register('city')}
                    dataErrors={errors['city']}
                  >
                    {watchedState &&
                      listCities?.map((city, index) => {
                        return (
                          <option key={index} value={city}>
                            {city}
                          </option>
                        );
                      })}
                  </FormSelect>
                  <FormSelect
                    className={css['form-container__input']}
                    id="dealerId"
                    label={t('label_dealerId_field')}
                    placeholder={t('placeholder_dealerId_field')}
                    dataRegister={register('dealerId')}
                    dataErrors={errors['dealerId']}
                  >
                    {listDealers &&
                      listDealers?.map((dealer, index) => {
                        return (
                          <option key={index} value={dealer.id}>
                            {dealer.name}
                          </option>
                        );
                      })}
                  </FormSelect>
                </div>

                {contactFormType === 'Atención al cliente' && (
                  <div data-grid-full-width>
                    <FormTextarea
                      id="comment"
                      className={css['form-container__input']}
                      placeholder={t('placeholder_message')}
                      label={t('label_message')}
                      maxLength="1000"
                      dataRegister={register('comment')}
                      dataErrors={errors['comment']}
                    />
                  </div>
                )}

                <div data-grid-full-width>
                  <FormCheckbox
                    displayError={false}
                    id="agreement"
                    dataRegister={register('agreement')}
                    dataErrors={errors['agreement']}
                    className={css['input-last-container']}
                  >
                    {t('label_agreement')}&nbsp;
                    <AnchorLink
                      size="medium"
                      link={t('link_agreement')}
                      hasArrow={false}
                      color="base-black"
                      linkLabel={t('link_label_agreement')}
                    />
                  </FormCheckbox>
                </div>
                <div data-grid-full-width className={css['form-submit-container']}>
                  <Button type="submit" data-testid="submit-button" disabled={!formState.isValid || isDisable}>
                    {t('button_submit_form')}
                  </Button>
                </div>
              </form>
            </>
          )}
        </Section>
      </Wrapper>
    </div>
  );
}

export default ContactForm;
