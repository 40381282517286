import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';
import PlayIcon from 'components/atoms/play-icon';

/**
 * Molecule Media
 *
 * A component that receives an object with media properties
 */
function Media(props) {
  const {
    className = '',
    url,
    type = 'img',
    alt = '',
    poster,
    centralizeItem = false,
    isFullContentSize = false,
    autoPlay,
    muted,
    loop,
    controls,
    ...other
  } = props;

  const [error, setError] = React.useState(false);
  const [videoActive, setVideoActive] = React.useState(false);

  React.useEffect((_) => {
    !url && setError(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const youtubeId = type === 'embed' && getYoutubeId(url);

  function getYoutubeId(url) {
    const id = url?.match('[\\?&]v=([^&#]*)')?.[1];
    return id;
  }

  function getUrl() {
    return `https://www.youtube.com/embed/${youtubeId}?autoplay=1`;
  }

  function getPoster() {
    return `https://i3.ytimg.com/vi/${youtubeId}/maxresdefault.jpg`;
  }

  return (
    <div
      className={`${css['molecule__media-container']} ${className}`}
      {...other}
      data-centralize-item={centralizeItem}
      data-is-full-content-size={isFullContentSize}
    >
      {error ? (
        console.error('Failed to render the media. The url I got was: ', url)
      ) : (
        <>
          {(type === 'img' || type === 'image') && (
            <img className={css['media-container-image']} onError={setError} alt={alt ? alt : ''} src={url} />
          )}

          {type === 'embed' && (
            <div className={css['embed-container']}>
              {videoActive ? (
                <iframe allow="autoplay" src={getUrl()} title={alt} />
              ) : (
                <div className={css['poster-container']}>
                  <button className={css['media-trigger']} onClick={() => setVideoActive(true)}>
                    <span data-visuallyhidden> Assistir ao vídeo {alt}</span>
                    <img
                      className={css['media-container-image']}
                      src={poster ? poster?.url : getPoster()}
                      alt={poster?.alt || alt}
                    />
                    <PlayIcon className={css['media-trigger-icon']} />
                  </button>
                </div>
              )}
            </div>
          )}

          {type === 'video' && (
            <video
              autoPlay={autoPlay}
              muted={muted}
              loop={loop}
              controls={controls}
              playsInline
              className={css['media-container-video']}
              poster="/images/media/thumbnail-video.png"
            >
              <source src={url} />
              <track kind="captions" />
            </video>
          )}
        </>
      )}
    </div>
  );
}

export default Media;
