/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import i18n from 'utils/i18n';
import { useUser } from '@auth0/nextjs-auth0';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';

// loading the sass style fot the component
import css from './styles.module.scss';
import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';
import { IconClose } from 'components/icons';
import AnchorLink from 'components/atoms/anchor-link';
import Modal from 'components/molecules/modal';
import Button from 'components/atoms/button';

/**
 * Organism HeaderLoginNavigation
 *
 * <!-- TODO: add a description here! -->
 */

function HeaderLoginNavigation(props) {
  const t = i18n.useTranslations('components.header-login-navigation');

  const { className = '', children, ...other } = props;

  const { user } = useUser();
  const router = useRouter();

  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isScreenSizeChecked, setIsScreenSizeChecked] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 1280px)' });
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && !isScreenSizeChecked) {
      setIsScreenSizeChecked(true);
    }
  }, []);

  const containerMenu = React.useRef(null);

  useEffect(() => {
    if (isMenuActive) {
      document.addEventListener('click', outsideMenuClick);
      document.addEventListener('keyup', toggleMenuWithKeyboard);
    }

    return () => {
      document.removeEventListener('click', outsideMenuClick);
      document.removeEventListener('keyup', toggleMenuWithKeyboard);
    };
  }, [isMenuActive]);

  function toggleMenu() {
    setIsMenuActive(!isMenuActive);
  }

  function outsideMenuClick(event) {
    if (event.target.closest('.login-sub-navigation') !== containerMenu.current) {
      setIsMenuActive(!isMenuActive);
    }
  }

  function toggleMenuWithKeyboard(evt) {
    if (evt.key === 'Escape' && isMenuActive) {
      toggleMenu();
    }
  }

  function toggleMenuAndNavigateToPage(page) {
    setIsMenuActive(!isMenuActive);
    router.push({ pathname: '/mi-toyota/login', query: `section=${page}` });
  }

  function renderUserIcon(user, isMobile) {
    const commonProps = { fill: 'none' };

    const connectedIcon = (
      <svg width="32" height="32" viewBox={isMobile ? '0 0 32 26' : '0 0 32 26'} {...commonProps}>
        <g>
          <path
            transform={!isMobile ? 'scale(0.8)' : undefined}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.8 16.3998C21.5 15.2998 22.7 13.4998 22.7 11.2998C22.7 7.9998 20 5.2998 16.7 5.2998C13.4 5.2998 10.7 7.9998 10.7 11.2998C10.7 13.4998 11.8 15.3998 13.6 16.3998C8.9 17.7998 5.4 22.0998 5.4 27.2998C5.3 27.6998 5.6 27.9998 6 27.9998H27.3C27.7 27.9998 28 27.6998 28 27.2998C28 22.1998 24.5 17.7998 19.8 16.3998Z"
            fill="black"
          />
        </g>
      </svg>
    );

    const mobileDisconnectedIcon = (
      <svg width="26" height="40" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...commonProps}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.814 16.4427C21.5259 15.3859 22.6668 13.4929 22.6668 11.3333C22.6668 8.0196 19.9805 5.33331 16.6668 5.33331C13.3531 5.33331 10.6668 8.0196 10.6668 11.3333C10.6668 13.4929 11.8077 15.3859 13.5197 16.4427C8.79155 17.8065 5.3335 22.1661 5.3335 27.3333C5.3335 27.7015 5.63197 28 6.00016 28H27.3335C27.7017 28 28.0002 27.7015 28.0002 27.3333C28.0002 22.1661 24.5421 17.8065 19.814 16.4427ZM12.0002 11.3333C12.0002 8.75598 14.0895 6.66665 16.6668 6.66665C19.2442 6.66665 21.3335 8.75598 21.3335 11.3333C21.3335 13.9106 19.2442 16 16.6668 16C14.0895 16 12.0002 13.9106 12.0002 11.3333ZM16.6668 17.3333C11.368 17.3333 7.03176 21.4546 6.6887 26.6666H26.645C26.3019 21.4546 21.9657 17.3333 16.6668 17.3333Z"
          fill="black"
        />
      </svg>
    );

    const desktopDisconnectedIcon = (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...commonProps}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.8604 12.332C16.1443 11.5395 17 10.1197 17 8.5C17 6.01472 14.9853 4 12.5 4C10.0147 4 8 6.01472 8 8.5C8 10.1197 8.85568 11.5395 10.1396 12.332C6.59354 13.3549 4 16.6246 4 20.5C4 20.7761 4.22386 21 4.5 21H20.5C20.7761 21 21 20.7761 21 20.5C21 16.6246 18.4065 13.3549 14.8604 12.332ZM9 8.5C9 6.567 10.567 5 12.5 5C14.433 5 16 6.567 16 8.5C16 10.433 14.433 12 12.5 12C10.567 12 9 10.433 9 8.5ZM12.5 13C8.52588 13 5.2737 16.091 5.0164 20H19.9836C19.7263 16.091 16.4741 13 12.5 13Z"
          fill="black"
        />
      </svg>
    );
    return user ? connectedIcon : isMobile ? mobileDisconnectedIcon : desktopDisconnectedIcon;
  }

  /*
   * This function handles the click behavior based on the user state for SA.
   * If the user has not finished filling the form it should be redirected to
   * the form without the possibility to navigate inside SA.
   */
  function handleUserIconClick() {
    const isFirstAccess = JSON.parse(window.sessionStorage.getItem('isFirstAccess'));
    const isOutsideOfSA = !window.location.pathname.includes('mi-toyota/login');

    if (isFirstAccess) {
      if (isOutsideOfSA) {
        router.push({ pathname: '/mi-toyota/login', query: { section: 'account' } });
      } else {
        changeModalState();
      }
      return;
    }
    toggleMenu();
  }

  async function exitForm() {
    router.push({ pathname: '/api/auth/logout' });
  }

  function changeModalState() {
    setIsModalOpen(!isModalOpen);
  }

  return (
    <div className={`${css['organism__header-login-navigation-container']} ${className}`} {...other}>
      <button className={css['login-action']} onClick={handleUserIconClick}>
        {isScreenSizeChecked && renderUserIcon(user, isMobile)}
      </button>
      <div ref={containerMenu} className="login-sub-navigation">
        <Wrapper style={{ display: isMenuActive ? 'block' : 'none' }} className={css['sub-navigation-wrapper']}>
          <Section className={css['sub-navigation-content']} tag="div">
            <AnchorLink
              linkLabel={t('previous-page')}
              removeUnderline
              invertArrow
              arrowPosition="left"
              onClick={toggleMenu}
              data-hide="desktop"
              className={css['sub-navigation__back-button']}
            />

            <ul style={{ display: 'block' }} className={css['sub-navigation-content-list']}>
              {user && !user.isFirstAccess ? (
                <li className={css['sub-navigation-content-list__item']}>
                  <strong className={css['sub-navigation__title']}>Área de usuario</strong>
                  <ul className={css['sub-navigation-extra-list']}>
                    <li className={css['sub-navigation-extra-list__item']}>
                      <AnchorLink
                        linkLabel="Mi vehículo"
                        removeUnderline={true}
                        hasArrow={false}
                        onClick={() => toggleMenuAndNavigateToPage('vehicles')}
                        data-is-selected={router?.query?.section === 'vehicles' ? true : null}
                      />
                    </li>
                    <li className={css['sub-navigation-extra-list__item']}>
                      <AnchorLink
                        linkLabel="Turnos"
                        removeUnderline={true}
                        hasArrow={false}
                        onClick={() => toggleMenuAndNavigateToPage('turnos')}
                        data-is-selected={router?.query?.section === 'turnos' ? true : null}
                      />
                    </li>
                    <li className={css['sub-navigation-extra-list__item']}>
                      <AnchorLink
                        linkLabel="Concesionarios"
                        removeUnderline={true}
                        hasArrow={false}
                        onClick={() => toggleMenuAndNavigateToPage('dealers')}
                        data-is-selected={router?.query?.section === 'dealers' ? true : null}
                      />
                    </li>
                    <li className={css['sub-navigation-extra-list__item']}>
                      <AnchorLink
                        linkLabel="Mi cuenta"
                        removeUnderline={true}
                        hasArrow={false}
                        onClick={() => toggleMenuAndNavigateToPage('account')}
                        data-is-selected={router?.query?.section === 'account' ? true : null}
                      />
                    </li>
                    <li className={css['sub-navigation-extra-list__item']}>
                      <AnchorLink linkLabel="Salir" link="/api/auth/logout" />
                    </li>
                  </ul>
                </li>
              ) : (
                <li className={css['sub-navigation-content-list__item']}>
                  <strong className={css['sub-navigation__title']}>¡Bienvenido!</strong>
                  <p className={css['sub-navigation__description']}>
                    Accedé a la información de tus vehículos, agendá turnos y revisá su historial.
                  </p>
                  <ul className={css['sub-navigation-extra-list']}>
                    <li className={css['sub-navigation-extra-list__item']}>
                      <AnchorLink linkLabel="Iniciar sesión" link="/api/auth/login" />
                    </li>
                    <li className={css['sub-navigation-extra-list__item']}>
                      <AnchorLink linkLabel="Crear cuenta" link="/api/auth/login?screen_hint=signup" />
                    </li>
                  </ul>
                </li>
              )}
            </ul>
            <button className={css['close-button']} data-hide="mobile" onClick={toggleMenu}>
              <IconClose className={css['close-button__icon']} />
              <span data-visuallyhidden>Fechar menu</span>
            </button>
          </Section>

          {isModalOpen && (
            <Modal
              modalWidth={isMobile ? '100%' : '630px'}
              modalHeight="235px"
              className={css['modal-container']}
              isOpen={isModalOpen}
              onClose={changeModalState}
              disabledclose={false}
              textContent="Todavía no completaste tu registo ¿Querés salir?"
              paddingContainer="30vh 12px 12px 12px"
              invertExitIcon={true}
              isContentBold={true}
            >
              <div className={css['modal-container-buttons']}>
                <Button style={{ marginRight: '10px' }} onClick={changeModalState} color="ghost-2">
                  Cancelar
                </Button>
                <Button onClick={exitForm}>Salir</Button>
              </div>
            </Modal>
          )}
        </Wrapper>
      </div>
    </div>
  );
}

export default HeaderLoginNavigation;
