import React, { useEffect, useRef, useState } from 'react';
// import i18n from 'utils/i18n';
import { updatePageParam } from 'utils/forms';
import { useUserCustomer } from 'providers/user-customer';
import { useServiceAppointment } from 'providers/service-appointment';
import { withPageAuthRequired } from '@auth0/nextjs-auth0';
import { useRouter } from 'next/router';
import AnchorLink from 'components/atoms/anchor-link';
import Loader from 'components/atoms/loader';
import MyDealers from 'components/organisms/my-dealers';
import MyShifts from 'components/molecules/my-shifts';
import MyVehicle from 'components/molecules/my-vehicle';
import Onelogin from 'components/molecules/onelogin';
import Section from 'components/utils/section';
import Wrapper from 'components/atoms/wrapper';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism ServiceAppointment
 *
 * <!-- TODO: add a description here! -->
 */
function ServiceAppointment(props) {
  const { className = '', oneLoginData, locale, dealers, dealersProduct, children, ...other } = props;

  const router = useRouter();
  const { userDataCustomer } = useUserCustomer();
  const [userRegisterData, setUserRegisterData] = useState({});
  const [isFirstAccess, setIsFirstAccess] = useState(false);
  const { state } = useServiceAppointment();
  const { currentStep } = state;
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [currentStep]);

  useEffect(() => {
    if (userDataCustomer && userDataCustomer.salesForceAccount) {
      setUserRegisterData(userDataCustomer?.salesForceAccount);
    }
  }, [userDataCustomer]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const checkFirstAccess =
        Object.keys(userRegisterData)?.length === 0 ||
        (!userDataCustomer.salesForceAccount && !userDataCustomer.customer);
      window.sessionStorage.setItem('isFirstAccess', checkFirstAccess);
      setIsFirstAccess(checkFirstAccess);
    }
  }, [userRegisterData]);

  const renderOneLoginForm = () => {
    return (
      <Onelogin
        userRegisterData={userRegisterData}
        oneLoginData={oneLoginData}
        locale={locale}
        isFirstAccess={isFirstAccess}
      />
    );
  };

  return (
    <div ref={containerRef} className={`${css['organism__service-appointment-container']} ${className}`} {...other}>
      <Wrapper spacing={6}>
        {isFirstAccess && router?.query?.section === 'account' ? (
          <div className={css['content-container']}>{renderOneLoginForm()}</div>
        ) : (
          <Section className={css['section-container']}>
            <ul className={css['navigation']}>
              <li className={css['navigation__item']}>
                <AnchorLink
                  linkLabel="Mi vehículo"
                  removeUnderline={true}
                  hasArrow={false}
                  onClick={() => updatePageParam('section', 'vehicles', router)}
                  data-is-selected={!router?.query?.section || router?.query?.section === 'vehicles' ? true : null}
                />
              </li>
              <li className={css['navigation__item']}>
                <AnchorLink
                  linkLabel="Turnos"
                  removeUnderline={true}
                  hasArrow={false}
                  onClick={() => updatePageParam('section', 'turnos', router)}
                  data-is-selected={router?.query?.section === 'turnos' ? true : null}
                />
              </li>
              <li className={css['navigation__item']}>
                <AnchorLink
                  linkLabel="Concesionarios"
                  removeUnderline={true}
                  hasArrow={false}
                  onClick={() => updatePageParam('section', 'dealers', router)}
                  data-is-selected={router?.query?.section === 'dealers' ? true : null}
                />
              </li>
              <li className={css['navigation__item']}>
                <AnchorLink
                  linkLabel="Mi cuenta"
                  removeUnderline={true}
                  hasArrow={false}
                  onClick={() => updatePageParam('section', 'account', router)}
                  data-is-selected={router?.query?.section === 'account' ? true : null}
                />
              </li>
              <li className={css['navigation__item']}>
                <AnchorLink linkLabel="Salir" link="/api/auth/logout" removeUnderline={true} hasArrow={false} />
              </li>
            </ul>
            <div className={css['content-container']}>
              {!router?.query?.section ||
                (router?.query?.section === 'vehicles' && (
                  <div>
                    <MyVehicle />
                  </div>
                ))}
              {router?.query?.section === 'turnos' && (
                <div>
                  <MyShifts dealersProduct={dealersProduct} locale={locale} userRegisterData={userRegisterData} />
                </div>
              )}
              {router?.query?.section === 'dealers' && (
                <div>
                  <MyDealers dealers={dealers} locale={locale} accessToken={userDataCustomer} />
                </div>
              )}
              {router?.query?.section === 'account' && <div>{renderOneLoginForm()}</div>}
            </div>
          </Section>
        )}
      </Wrapper>
    </div>
  );
}

export default withPageAuthRequired(ServiceAppointment, {
  onRedirecting: () => <Loader />
});
