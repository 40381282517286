import React, { useEffect, useState } from 'react';
import FormRadioButton from 'components/atoms/form-radio-button';
import FormRadioGroup from 'components/atoms/form-radio-group';
import SvgIconAttention from 'components/icons/IconAttention';
import FormSelect from 'components/atoms/form-select';
import FormTextArea from 'components/atoms/form-text-area';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from 'components/atoms/button';
import FaqList from 'components/molecules/faq-list';
import Title from 'components/atoms/title';
import FormCheckbox from 'components/atoms/form-checkbox';
import i18n from 'utils/i18n';
import { useServiceAppointment } from 'providers/service-appointment';
import LoaderOverlay from 'components/molecules/loader-overlay';
import css from './styles.module.scss';

function ServiceAppointmentServicesStep(props) {
  const t = i18n.useTranslations('components.service-appointment-services-step');
  const { state, dispatch } = useServiceAppointment();

  const { className = '', children, onPreviousStep, onNextStep, locale, ...other } = props;
  const [listKm, setListKm] = useState([]);
  const [selectedKm, setselectedKm] = useState();
  const [isOneServiceSelected, setIsOneServiceSelected] = useState(true);
  const [capaignsList, setCampaignsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const chassi = state.selectedVehicleInfo && state.selectedVehicleInfo.chassi;
  const [isThereAppointments, setIsThereAppointments] = useState(false);
  const [isThereAppointmentsWithRecall, setIsThereAppointmentsWithRecall] = useState(false);
  const [isOthersRequired, setIsOthersRequired] = useState(false);

  const validationSchema = yup.object().shape({
    hasRecall: yup.string().oneOf(['true', 'false']),
    km: yup.string().trim(),
    additionalServices: yup.array(),
    hasWash: yup.boolean(),
    others: yup.string()
  });

  const { register, watch, setValue, getValues } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      hasRecall: state.hasRecall,
      km: state.km,
      hasWash: state.hasWash || false,
      additionalServices: state.additionalServices,
      others: state.others || ''
    }
  });

  const watchRecall = watch('hasRecall');
  const watchKm = watch('km');
  const watchWash = watch('hasWash');
  const watchOthers = watch('others');
  const watchAdditionalServices = watch('additionalServices');

  const fetchCampaigns = async () => {
    try {
      const response = await fetch(`/api/recall/getCampaigns?chassi=${chassi}`);
      const data = await response.json();

      if (data && data.data && data.data.recallFromTasaApiByChassis) {
        const filteredCampaignsList = data.data.recallFromTasaApiByChassis.filter(
          (item) => item.status === 'Campaña sin realizar'
        );
        return filteredCampaignsList;
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      return [];
    }
  };

  const fetchMaintenanceData = async () => {
    try {
      const clientVehicleId = state.selectedVehicleInfo && state.selectedVehicleInfo.externalId;

      if (!clientVehicleId) {
        throw new Error('Client Vehicle ID not found.');
      }

      const response = await fetch(
        `/api/maintenance/getMaintenance?locale=${locale}&clientVehicleId=${clientVehicleId}`
      );

      if (!response.ok) {
        throw new Error('Failed to fetch maintenance data.');
      }

      const { maintenanceItems } = await response.json();

      if (!maintenanceItems || !maintenanceItems?.length) {
        console.error('No valid maintenance items found.');
        return [];
      }

      return maintenanceItems;
    } catch (error) {
      console.error('Error fetching maintenance data:', error);
      return [];
    }
  };

  const loadData = async () => {
    try {
      const campaignsList = await fetchCampaigns();
      setCampaignsList(campaignsList);

      if (campaignsList?.length === 0) {
        setValue('hasRecall', 'false');
      }

      const maintenanceItems = await fetchMaintenanceData();
      setListKm(maintenanceItems);
    } catch (error) {
      console.error('Error loading data:', error);
    }

    setLoading(false);
  };

  function validateForm() {
    const oneOptionSelected =
      watchRecall === 'true' ||
      (watchKm !== '' && watchKm !== null && watchKm !== 'placeholder') ||
      (watchOthers !== '' && watchOthers !== null && watchOthers !== 'placeholder');
    const oneOptionSelectedWithoutRecall =
      (watchKm !== '' && watchKm !== null && watchKm !== 'placeholder') ||
      (watchOthers !== '' && watchOthers !== null && watchOthers !== 'placeholder');
    const othersRequired = watchOthers !== '' && watchOthers !== null && watchOthers !== 'placeholder';

    oneOptionSelected ? setIsOneServiceSelected(true) : setIsOneServiceSelected(false);
    othersRequired ? setIsOthersRequired(false) : setIsOthersRequired(true);

    if (capaignsList?.length === 0 && oneOptionSelectedWithoutRecall) {
      setIsOneServiceSelected(true);
    }
    if (capaignsList?.length === 0 && !oneOptionSelectedWithoutRecall) {
      setIsOneServiceSelected(false);
      updateNextStep(true);
    }
  }

  function updateNextStep(boolean) {
    const updatedListSteps = state.listSteps.map((step) => {
      if (step.type === 'DEALERSHIP') {
        return { ...step, disabled: boolean };
      }
      return step;
    });
    dispatch({ type: 'UPDATE_LIST_STEP', payload: updatedListSteps });
  }

  const updateStepsAndProceed = (updatedListSteps) => {
    dispatch({ type: 'UPDATE_LIST_STEP', payload: updatedListSteps });
    const nextStepIndex = state.currentStep + 1;
    dispatch({ type: 'UPDATE_NEXT_STEP', payload: nextStepIndex });
  };

  const handleOthers = () => {
    const isPriceDateStepPresent = state?.listSteps?.some((item) => item.type === 'PRICE_DATE');

    const shouldAddAdditionalSteps =
      !watchOthers && !isPriceDateStepPresent && state.availableForAppointments !== false;

    const shouldRemoveAdditionalSteps =
      (watchOthers && isPriceDateStepPresent) || state.availableForAppointments === false;

    if (shouldAddAdditionalSteps) {
      const additionalSteps = [
        {
          type: 'PRICE_DATE',
          name: 'Precio y Fecha',
          description: 'Seleccioná el día y hora para la realización del turno.',
          disabled: true,
          order: 3
        },
        {
          type: 'ATTENDEE',
          name: 'Persona que asistirá',
          description: 'Informá la persona que va a asistir al turno agendado.',
          disabled: true,
          order: 4
        }
      ];
      const updatedListSteps = [...state.listSteps, ...additionalSteps];
      updateStepsAndProceed(updatedListSteps);
    } else if (shouldRemoveAdditionalSteps) {
      const stepsToDelete = ['PRICE_DATE', 'ATTENDEE'];
      const updatedListSteps = state.listSteps.filter((step) => !stepsToDelete.includes(step.type));
      updateStepsAndProceed(updatedListSteps);
    }
  };

  useEffect(() => {
    loadData();
    updateNextStep(false);
    if (watchKm !== null) {
      return;
    } else {
      setValue('km', 'placeholder');
    }
  }, []);

  useEffect(() => {
    const { appointments } = state.selectedVehicleInfo || {};

    if (appointments && appointments.length > 0) {
      setIsThereAppointments(true);
      const hasRecall = appointments?.some((item) => item?.hasRecall === true);
      setIsThereAppointmentsWithRecall(hasRecall);
    } else {
      setIsThereAppointments(false);
      setIsThereAppointmentsWithRecall(false);
    }
  }, [state.selectedVehicleInfo]);

  useEffect(() => {
    validateForm();
    handleOthers();
    validateOthersField();
  }, [
    capaignsList,
    watchRecall,
    watchKm,
    watchOthers,
    watchAdditionalServices
  ]);

  function handleValues() {
    const additionalServices = getValues('additionalServices');
    const recall = capaignsList.length > 0 ? watchRecall : false;
    dispatch({ type: 'SET_HAS_RECALL', payload: recall });
    dispatch({ type: 'SET_KM', payload: watchKm });
    dispatch({ type: 'SET_HAS_WASH', payload: watchWash });
    dispatch({ type: 'SET_ADDITIONAL_SERVICES', payload: additionalServices });
    dispatch({ type: 'SET_OTHERS_SELECTED', payload: watchOthers });
    dispatch({ type: 'SET_SELECTED_DEALER_ID', payload: null });
    dispatch({ type: 'SET_SELECTED_SLOT', payload: null });
    dispatch({ type: 'CLEAR_DISABLED_DEALERS' });
  }

  const validateOthersField = () => {
    if (watchRecall === 'true' || watchKm !== 'placeholder') {
      setIsOthersRequired(false);
    } else if (listKm?.length === 0) {
      setIsOthersRequired(true);
    } else {
      setIsOthersRequired(false);
    }
  };

  function onClickEliminar() {
    setValue('km', 'placeholder');
    setValue('hasWash', false);
    dispatch({ type: 'SET_AVAILABLE_FOR_APPOINTMENTS', payload: null });
  }

  const handleConfirmAppointment = () => {
    handleValues();
    updateNextStep(true);
    onNextStep();
  };

  function onChangeKm(e) {
    setValue('km', e.currentTarget.value);

    const kmData = listKm.filter((item) => item.title === e.currentTarget.value);
    const servicios = {
      items: kmData[0]?.contents?.map((service) => {
        return {
          title: service.title,
          content: service.description
        };
      })
    };
    setselectedKm(servicios);
    dispatch({ type: 'SET_AVAILABLE_FOR_APPOINTMENTS', payload: kmData[0]?.availableForAppointments });
  }

  return (
    <div className={`${css['molecule__service-appointment-services-step-container']} ${className}`} {...other}>
      {loading && <LoaderOverlay content={t('content_loading')} />}
      {!loading && (
        <>
          {capaignsList?.length > 0 && (
            <div className={isThereAppointmentsWithRecall ? `${css['disabled']} ${css['recall']}` : `${css['recall']}`}>
              <div className={css['recall-body']}>
                <div className={css['item-title-container']}>
                  <div className={css['icon-container']}>
                    <SvgIconAttention color={isThereAppointmentsWithRecall ? '#A5A5A5' : '#000000'} />
                  </div>
                  <Title level={3} variant={3} className={isThereAppointmentsWithRecall ? `${css['disabled']}` : null}>
                    {t('recall_title')}
                  </Title>
                </div>
                <ul
                  className={
                    isThereAppointmentsWithRecall ? `${css['disabled']} ${css['campaigns']}` : `${css['campaigns']}`
                  }
                >
                  {capaignsList?.length > 1 ? <p>{t('more_campaigns')}</p> : <p>{t('one_campaign')}</p>}
                  {capaignsList?.map((campana, index) => {
                    return <li key={index}>&bull; {campana.name}</li>;
                  })}
                </ul>
                {!isThereAppointmentsWithRecall && (
                  <div className={css['recall-radio']}>
                    <FormRadioGroup
                      name="recall"
                      className={css['recall-radio-group']}
                      label={t('recall_radio')}
                      dataRegister={register('hasRecall')}
                      spacingGap="standard"
                    >
                      <FormRadioButton
                        key="1"
                        id="1"
                        value={true}
                        defaultChecked={state.hasRecall === 'true' || watchRecall === null}
                      >
                        {t('option_1')}
                      </FormRadioButton>
                      <FormRadioButton key="2" id="2" value={false}>
                        {t('option_2')}
                      </FormRadioButton>
                    </FormRadioGroup>
                    {watchRecall === 'false' && (
                      <div className={css['message-option-no']}>
                        <SvgIconAttention height="16" width="16" />
                        <span>{t('option_2_message')}</span>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className={css['legal-text-recall-container']}>
                <div className={`${isThereAppointmentsWithRecall ? css['disabled'] : ''} ${css['legal-text-recall']}`}>
                  <div className={css['legal-text-message-revision']}>
                    {watchRecall === 'false' && <p>{t('paragraph-legal-text-without-recall')}</p>}
                    <p> {t('first_paragraph_legal_text')}</p>
                    <p>
                      {t('second-paragraph-legal-text')}
                      <a
                        href={t('link-to-revision-page')}
                        title={t('link-revision-page-description')}
                        aria-label={t('link-revision-page-description')}
                      >
                        {t('link-to-revision-page')}
                      </a>
                      .
                    </p>
                  </div>
                </div>
                {isThereAppointmentsWithRecall && (
                  <p className={css['appointment-message-with-recall']}>{t('recall_message_appointment')}</p>
                )}
              </div>
            </div>
          )}
          {listKm?.length > 0 && (
            <>
              <div
                className={isThereAppointments ? `${css['maintenance']} ${css['disabled']}` : `${css['maintenance']}`}
              >
                <div className={css['maintenance-title']}>
                  <Title level={3} variant={3} className={isThereAppointments ? `${css['disabled']}` : null}>
                    {t('maintenance_title')}
                  </Title>
                  <p className={css['description']}>{t('maintenance_description')}</p>
                </div>
                {!isThereAppointments && listKm?.length > 0 && (
                  <div className={css['select']}>
                    <FormSelect
                      id="km"
                      label={t('maintenance_label')}
                      placeholder={t('maintenance_placeholder')}
                      dataRegister={register('km')}
                      onChange={onChangeKm}
                    >
                      {listKm?.map((item, index) => {
                        return (
                          <option key={index} value={item.title}>
                            {item.title}
                          </option>
                        );
                      })}
                    </FormSelect>
                    {watchKm !== 'placeholder' && (
                      <div>
                        <Button className={css['eliminate']} onClick={onClickEliminar}>
                          {t('eliminate_button')}
                        </Button>
                      </div>
                    )}
                  </div>
                )}
                {isThereAppointments && <p className={css['appointment-message']}>{t('appointment_message')}</p>}

                {watchKm !== 'placeholder' && selectedKm?.items?.length > 0 && (
                  <div className={css['services']}>
                    <div className={css['included-services']}>
                      <Title level={3} variant={3}>
                        {t('included_services_title')}
                      </Title>

                      <FaqList className={css['faq']} faqContent={selectedKm} />
                      <p className={css['message_services']}>{t('message_services')}</p>
                    </div>
                  </div>
                )}

                {watchKm !== 'placeholder' && (
                  <div className={css['wash-container']}>
                    <Title level={3} variant={3}>
                      {t('wash_title')}
                    </Title>
                    <FormCheckbox
                      className={css['checkbox']}
                      id="wash"
                      label={t('wash_label')}
                      dataRegister={register('hasWash')}
                      defaultChecked={state.hasWash}
                    />
                  </div>
                )}
              </div>
            </>
          )}

          <div className={css['additional-services-container']}>
            <Title level={3} variant={3}>
              {t('additional_services_title')}
            </Title>
            <FormCheckbox
              className={css['checkbox']}
              id="lamps"
              label={t('lamps_label')}
              value={t('lamps_label')}
              dataRegister={register('additionalServices')}
              defaultChecked={state.additionalServices.includes(t('lamps_label'))}
            />
            <FormCheckbox
              className={css['checkbox']}
              id="battery"
              label={t('battery_label')}
              value={t('battery_label')}
              dataRegister={register('additionalServices')}
              defaultChecked={state.additionalServices.includes(t('battery_label'))}
            />
            <FormCheckbox
              className={css['checkbox']}
              id="tablets"
              label={t('tablets_label')}
              value={t('tablets_label')}
              dataRegister={register('additionalServices')}
              defaultChecked={state.additionalServices.includes(t('tablets_label'))}
            />
            <FormCheckbox
              className={css['checkbox']}
              id="brushes"
              label={t('brushes_label')}
              value={t('brushes_label')}
              dataRegister={register('additionalServices')}
              defaultChecked={state.additionalServices.includes(t('brushes_label'))}
            />
          </div>
          <div className={css['others']}>
            <Title level={3} variant={3}>
              {isThereAppointments || isOthersRequired ? t('others_title_required') : t('others_title')}
            </Title>
            <FormTextArea
              rows="3"
              id="others"
              label={t('others_label')}
              placeholder={t('others_placeholder')}
              dataRegister={register('others')}
              required={isOthersRequired}
            />
          </div>
          <div className={css['buttons']}>
            <Button
              disabled={isThereAppointments ? isOthersRequired : !isOneServiceSelected}
              onClick={() => handleConfirmAppointment()}
            >
              {t('confirm_button')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default ServiceAppointmentServicesStep;
