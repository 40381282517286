/* eslint-disable @typescript-eslint/no-explicit-any */
import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

import Vehicle from 'utils/repo/vehicle';
import Dealers from 'utils/repo/dealers';

/**
 * Extracts a unique list of states from an array of dealers.
 *
 * @see utils/forms - Duplicated from utils because of limitation on current Rollup configuration
 * @param {Array} dealers - Array of dealers with address information.
 * @returns {Array} - A sorted array of unique states.
 */
export function getStatesFromDealers(dealers) {
  if (!Array.isArray(dealers)) {
    return [];
  }

  const resultStates = dealers
    .flatMap((dealer) => (dealer?.address?.state ? [dealer.address.state.toUpperCase()] : []))
    .filter((state) => state && state.trim() !== '')
    .sort();
  return [...new Set(resultStates)];
}

class ContactForm implements ContentBlock {
  label = 'Contact Form TDV';

  component = 'ContactForm';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'contactFormType',
      label: 'Contact Form Type (required)',
      help: 'Select the type of contact form you are using. This helps in routing the form data appropriately.',
      type: 'String',
      required: true,
      options: [
        'Presupuesto',
        'Atención al cliente',
        'Recall'
      ],
      defaultValue: 'Presupuesto',
      control: 'select'
    },
    {
      name: 'title',
      label: 'Title form',
      help: 'The title of the form',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'description',
      label: 'Description',
      help: 'Is the description of the form',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'titleSuccess',
      label: 'Title success',
      help: 'The title used in the sent form success response modal',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'contentSuccess',
      label: 'Content success',
      help: 'Descriptive text used in the sent form success response modal',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'titleError',
      label: 'Title error',
      help: 'The title used in the sent form error response modal',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'contentError',
      label: 'Content error',
      help: 'Descriptive text used in the sent form error response modal',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'headerSpacing',
      label: 'Header spacing',
      help: 'Is the spacing between the header with title and description of the form',
      type: 'Number',
      control: 'input:number',
      defaultValue: '0'
    }
  ];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    const locale = extra?.context?.locale;
    const productId = extra?.context?.productId;
    const models = [];

    const vehiclesByModels = (await dataFetcher(Vehicle.getAllVehicles(), { locale: locale }))?.data?.vehicles || [];

    const dealersByLocation =
      (await dataFetcher(Dealers?.getAllDealersEssential(), { productId: productId }))?.data?.dealers || [];

    for (const model of vehiclesByModels) {
      models.push({
        name: model.name || '',
        vehicleExternalId: model?.vehicleExternalId || '',

        mainVersionName: model?.mainVersion?.name || '',
        mainVersionCode: model?.mainVersion?.modelYear?.clientVehicleId || '',

        mainVersionExternalColor: {
          name: model?.mainVersion?.modelYear?.vehicleColors?.[0]?.color?.name || '',
          code: model?.mainVersion?.modelYear?.vehicleColors?.[0]?.color?.code || ''
        },

        versions: model?.versions || []
      });
    }

    const states = getStatesFromDealers(dealersByLocation);

    return {
      locale: String(locale).toLowerCase(),
      title: extra?.settings?.title,
      description: extra?.settings?.description,
      titleSuccess: extra?.settings?.titleSuccess,
      contentSuccess: extra?.settings?.contentSuccess,
      titleError: extra?.settings?.titleError,
      contentError: extra?.settings?.contentError,
      headerSpacing: extra?.settings?.headerSpacing || '0',
      contactFormType: extra?.settings?.contactFormType,
      models: models,
      dealers: dealersByLocation,
      states: states
    };
  }
}

export { ContactForm };
