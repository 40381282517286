import React, { useState } from 'react';
import i18n from 'utils/i18n';

import FormSelect from '/components/atoms/form-select';
import Button from '/components/atoms/button';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule RecallList
 */
function RecallList(props) {
  const t = i18n.useTranslations('components.recall-list');

  const { className = '', title, recallData = [], ...other } = props;

  const visibleItemsDefaultSize = 5;

  const [selectedFilter, setSelectedFilter] = useState('all');
  const [visibleItems, setVisibleItems] = useState(visibleItemsDefaultSize);

  const filterOptions = [...new Set(recallData?.map((recall) => recall?.model))];

  const data =
    selectedFilter == 'all'
      ? recallData
      : recallData?.filter((recall) => recall?.model?.toLowerCase()?.trim() == selectedFilter);

  function onChangeFilter(e) {
    const selected = e?.target?.value;
    setSelectedFilter(selected);
    setVisibleItems(visibleItemsDefaultSize);
  }

  function loadMoreCards() {
    setVisibleItems((previousValue) =>
      previousValue + visibleItemsDefaultSize > data?.length ? data?.length : previousValue + visibleItemsDefaultSize
    );
  }

  return (
    <div className={`${css['molecule__recall-list-container']} ${className}`} {...other}>
      <h1 className={css['title']}>{title ? title : t('title')}</h1>

      <FormSelect
        className={css['filter']}
        id="filter"
        label={t('label_search_input')}
        placeholder={t('placeholder_search_input')}
        onChange={onChangeFilter}
      >
        <option value="all">{t('option_search_input')}</option>

        {filterOptions?.map((model, key) => (
          <option key={key} value={model?.toLowerCase()?.trim()}>
            {model}
          </option>
        ))}
      </FormSelect>

      <ul className={css['card-list-container']}>
        {data?.slice(0, visibleItems)?.map((item, key) => (
          <li className={css['card']} key={key}>
            <div className={css['card-items']}>
              {item?.model && (
                <div className={css['card-item']}>
                  <h2>{t('card_title_model')}</h2>
                  <p>{item?.model}</p>
                </div>
              )}

              {item?.description && (
                <div className={css['card-item']}>
                  <h2>{t('card_title_recall')}</h2>
                  <p>{item?.name}</p>
                </div>
              )}
            </div>

            {item?.medias[0]?.url && (
              <div className={css['card-link']}>
                <a href={item.medias[0].url}>{t('card_title_link')}</a>
                <img alt="download icon" src="/images/recall/icn-download.svg" />
              </div>
            )}
          </li>
        ))}
      </ul>

      {visibleItems < data.length && (
        <Button size="large" color="ghost-2" className={css['button']} onClick={loadMoreCards}>
          {t('button_load_more')}
          <img src="/images/recall/icn-arrow-down.svg" alt="see more icon" />
        </Button>
      )}
    </div>
  );
}

export default RecallList;
