import RecallData from 'utils/repo/recall';
import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

class Recall implements ContentBlock {
  label = 'Recall';

  component = 'Recall';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'searchSectionTitle',
      label: 'Search section title',
      help: 'Enter the search section title.',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'filterSectionTitle',
      label: 'Filter section title',
      help: 'Enter the filter section title.',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    }
  ];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    const productId = extra?.context?.productId;
    if (!productId) {
      throw new Error(`There is no productId defined.`);
    }

    const locale = extra?.context?.locale;
    if (!locale) {
      throw new Error(`There is no locale defined.`);
    }

    const recalls = (await dataFetcher(RecallData.getAllRecalls(), { locale }))?.data?.recalls;
    const settings = extra?.settings;

    return {
      recalls: recalls || [],
      searchSectionTitle: settings.searchSectionTitle.trim(),
      filterSectionTitle: settings.filterSectionTitle.trim(),
      locale
    };
  }
}

export { Recall };
