/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
//import i18n from 'utils/i18n';
import ReactModal from 'react-modal';
import Title from 'components/atoms/title';
import CloseButton from 'components/atoms/close-button';

// loading the sass style fot the component
import css from './styles.module.scss';

function Modal(props) {
  //const gt = i18n.useTranslations('global');
  //const t = i18n.useTranslations('components.modal');

  const {
    className = '',
    modalClassName = '',
    title,
    textContent,
    modalIsOpen = true,
    onClose = null,
    backGroundColor = 'rgba(0, 0, 0, 0.4)',
    paddingContainer = '2.4rem',
    displayContainer = 'flex',
    justifyContent = 'center',
    overflowContainer = 'auto',
    scrollBehavior = 'smooth',
    modalWidth = '100%',
    modalHeight = 'auto',
    zIndex = '1000',
    showCloseButton = true,
    variant,
    children,
    invertExitIcon = false,
    isContentBold = false,
    ...other
  } = props;

  const [color, setColor] = React.useState('');
  const [display, setDisplay] = React.useState('');
  const [padding, setPadding] = React.useState('');
  const [justify, setJustify] = React.useState('');
  const [overflow, setOverflow] = React.useState('');
  const [scrool, setScrool] = React.useState('');

  React.useEffect((_) => {
    setColor(backGroundColor);
    setPadding(paddingContainer);
    setDisplay(displayContainer);
    setJustify(justifyContent);
    setOverflow(overflowContainer);
    setScrool(scrollBehavior);
  }, []);

  ReactModal.defaultStyles.overlay.backgroundColor = color;
  ReactModal.defaultStyles.overlay.display = display;
  ReactModal.defaultStyles.overlay.justifyContent = justify;
  // ReactModal.defaultStyles.overlay.padding = padding;
  ReactModal.defaultStyles.overlay.overflow = overflow;
  ReactModal.defaultStyles.overlay.scrollBehavior = scrool;
  ReactModal.defaultStyles.overlay.zIndex = zIndex;

  if (typeof padding === 'string') {
    ReactModal.defaultStyles.overlay.padding = padding;
  } else if (Array.isArray(padding)) {
    ReactModal.defaultStyles.overlay.paddingTop = padding[0];
    ReactModal.defaultStyles.overlay.paddingLeft = padding[1];
    ReactModal.defaultStyles.overlay.paddingBottom = padding[2];
    ReactModal.defaultStyles.overlay.paddingRight = padding[3];
  } else {
    if (padding?.top) {
      ReactModal.defaultStyles.overlay.paddingTop = padding.top;
    }
    if (padding?.left) {
      ReactModal.defaultStyles.overlay.paddingLeft = padding.left;
    }
    if (padding?.bottom) {
      ReactModal.defaultStyles.overlay.paddingBottom = padding.bottom;
    }
    if (padding?.right) {
      ReactModal.defaultStyles.overlay.paddingRight = padding.right;
    }
  }

  return (
    <div className={css['molecule__modal-container']}>
      <ReactModal
        data-variant={variant}
        ariaHideApp={false}
        className={`${css['modal']} ${modalClassName}`}
        style={{ content: { width: modalWidth, height: modalHeight } }}
        isOpen={modalIsOpen}
        onRequestClose={onClose}
      >
        <div className={css['header']} data-invert-exit-icon={invertExitIcon}>
          {title && <Title variant={2}>{title}</Title>}
          {showCloseButton && <CloseButton color="black" onClick={onClose} />}
        </div>
        <div className={css['content']}>
          {textContent && (
            <p className={css['content__content']} data-is-bold={isContentBold}>
              {textContent}
            </p>
          )}
          <div className={`${className}`} {...other}>
            {children}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

export default Modal;
