const getAllRecalls = () => {
  return `
    query recalls (
      # variables
      $locale: String
    ){
      recalls(locale : $locale) {
        id
        name
        code
        model
        modelYear
        description
        medias {
          url
        }
        startDateCampaign
      }
    }
  `;
};

const getRecallByChassiOrBoardViaDetran = () => {
  return `query recallByChassiOrBoardViaDetran (
    # variables
    $param: String!
  ){
    recallByChassiOrBoardViaDetran(param : $param) {
      code
      repairDate
      name
      id
      file {
        url
      }
      description
      model
      repairStatus
      denatranCode
      chassi
      board
      dealership {
        name
        uf
        address
        city
      }
      duration
    }
  }`;
};

const getRecallVehiclesByChassiOrPatent = () => {
  return `
  query recallVehiclesByChassiOrPatent(
    $chassiOrPatent: String!
    $locale: String
){
  recallVehiclesByChassiOrPatent(
    chassiOrPatent: $chassiOrPatent,
    locale : $locale
  ){
    status
    vehicle {
      chassi
      id
      patent
    }
  }
}
  `;
};

const recallFromTasaApiByChassis = () => {
  return `
query recallFromTasaApiByChassis($chassi : String!){
  recallFromTasaApiByChassis(chassi : $chassi) {
      id
      name
      status
  }
}`;
};

const recallFromTDVApi = () => {
  return `
  query recallFromTDVApi($filter: FilterRecallTDVApi!) {
	  recallFromTDVApi(filter: $filter ) {
    recallCampaigns {
      code
      name
      description
    }
    attended
    exists
    dealer
    approvationDate
  }
}`;
};

const Recall = {
  getAllRecalls,
  getRecallByChassiOrBoardViaDetran,
  getRecallVehiclesByChassiOrPatent,
  recallFromTasaApiByChassis,
  recallFromTDVApi
};

export default Recall;
