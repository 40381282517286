import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

class ActionBannerItem implements ContentBlock {
  label = 'Action Banner Item';

  component = 'ActionBannerItem';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'variant',
      label: 'Banner layout variant',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        {
          key: '2',
          value: '2',
          text: 'Banner stays inside the page container'
        }
      ],
      defaultValue: null
    },
    {
      name: 'titleOnly',
      label: 'Align the text at the bottom if the banner',
      help: '',
      type: 'boolean',
      control: 'toggle',
      defaultValue: true
    },
    {
      name: 'removeMask',
      label: 'Remove the background mask of the banner',
      help: '',
      type: 'boolean',
      control: 'toggle',
      defaultValue: true
    },
    {
      name: 'containerSize',
      label: 'The size of the container',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        'default',
        'small',
        'medium'
      ]
    },
    {
      name: 'marginTop',
      label: 'The margin space at the top of the banner ',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ]
    },
    {
      name: 'marginBottom',
      label: 'The margin space at the bottom of the banner ',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ]
    }
  ];

  properties = [
    {
      name: 'title',
      label: 'Title',
      help: 'This field accepts a maximum of 95 characters',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: { maxLength: '95' }
    },
    {
      name: 'description',
      label: 'Content',
      help: 'This field accepts a maximum of 120 characters',
      type: 'string',
      control: 'textarea',
      defaultValue: '',
      props: { maxLength: '120' }
    },
    {
      name: 'media',
      label: 'Banner desktop image',
      help: 'Set the banner desktop image',
      type: 'media',
      control: 'upload',
      defaultValue: '',
      props: null
    },
    {
      name: 'mediaMob',
      label: 'Banner mobile image',
      help: 'Set the banner mobile image',
      type: 'media',
      control: 'upload',
      defaultValue: '',
      props: null
    },
    {
      name: 'callToActions[].label',
      label: 'Link label',
      help: '',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'callToActions[].target',
      label: 'Link target',
      help: '',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    }
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async adapter(contents: any[], extra: any): Promise<object> {
    const content = contents[0];

    return {
      titleOnly: extra?.settings?.titleOnly,
      removeMask: extra?.settings?.removeMask,
      containerSize: extra?.settings?.containerSize,
      title: content?.title || null,
      description: content?.description || null,
      bannerImage: {
        desktop: content?.media?.url || null,
        mobile: content?.mediaMob?.url || null
      },
      cta: content?.callToActions?.[0]?.label
        ? {
            text: content?.callToActions?.[0]?.label || null,
            action: content?.callToActions?.[0]?.target || null
          }
        : null,
      link: content?.callToActions?.[1]?.label
        ? {
            label: content?.callToActions?.[1]?.label || null,
            url: content?.callToActions?.[1]?.target || null
          }
        : null
    };
  }
}

export { ActionBannerItem };
